<template>
  <div>
    <label :for="`uploadImage${this.id}`" class="cursor-pointer">
      <slot></slot>
    </label>
    <input :id="`uploadImage${this.id}`" accept="image/*" class="hidden" type="file" @change="uploadImage" />
  </div>
</template>

<script>
import Compressor from "compressorjs"
import randomId from "@/lib/utils/random-id"

export default {
  name: "UploadImage",
  data() {
    return {
      previewImage: null,
      id: ""
    }
  },
  methods: {
    uploadImage(e) {
      const file = e.target.files[0]
      if (!file) {
        return
      }
      const v = this
      new Compressor(file, {
        quality: 0.8,
        maxWidth: 1600,
        maxHeight: 1000,
        success(result) {
          const reader = new FileReader()
          reader.readAsDataURL(result)
          reader.onload = (e) => {
            this.previewImage = e.target.result
            v.$emit("upload", this.previewImage)
          }
        },
        error(err) {
          console.log(err.message)
        }
      })

      // const image = e.target.files[0];
      // const reader = new FileReader();
      // reader.readAsDataURL(image);
      // reader.onload = e => {
      //   this.previewImage = e.target.result;
      //   console.log("upload completed")
      //   this.$emit("upload", this.previewImage);
      // };
    }
  },
  mounted() {
    this.id = randomId()
  }
}
</script>
