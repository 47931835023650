<template>
  <dropdown-select v-model="fitValue" @change="set">
    <option value="cover">Cover</option>
    <option value="contain">Contain</option>
    <option value="fill">Fill</option>
    <option value="none">None</option>
  </dropdown-select>
</template>

<script>
import { mapGetters } from "vuex"
import imageToolMixin from "@/mixins/images/image-tool-mixin"
import DropdownSelect from "@/components/common/DropdownSelect"

export default {
  name: "ImageObjectWidth",
  components: { DropdownSelect },
  computed: {
    ...mapGetters("preview", ["getCurrentStyleVariables"])
  },
  mixins: [imageToolMixin],
  data() {
    return {
      fitValue: ""
    }
  },
  methods: {
    fillDataFromImage() {
      if (this.img.style["object-fit"]) {
        this.fitValue = this.img.style["object-fit"]
      }
    },
    set() {
      this.img.style["object-fit"] = this.fitValue
    }
  },
  mounted() {
    const styleVars = this.getCurrentStyleVariables.style[this.env]
    if (!this.img.style["object-fit"]) {
      this.img.style["object-fit"] = styleVars["--image-object-fit"]
    }
    this.fillDataFromImage()
  }
}
</script>
