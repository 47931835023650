<template>
  <div class="preview-area flex flex-1 h-full max-h-full max-w-full flex-col bg-gray-200">
    <div ref="preview" class="preview flex overflow-x-auto overflow-y-auto">
      <div v-for="(value, key) in previewDevices" :key="key" class="device mt-5 mr-4 ml-4 mb-2">
        <iframe
          :ref="key"
          :srcdoc="html"
          :style="[getPreviewDevicesLoading ? { visibility: 'hidden' } : {}]"
          class="shadow-md w-full h-full"
          style="transform-origin: center center; transform: scale(1)"
          @load="load(key)"
        ></iframe>
      </div>
    </div>
    <div class="h-12 border-t border-gray-200">
      <div class="flex bg-white items-center justify-between h-full px-4">
        <div class="flex items-center">
          <p class="font-medium">Mobile</p>
          <MobileResolutionSelector class="mx-4" />

          <div class="flex items-center bg-gray-200 px-2 py-1 rounded-md">
            <button class="flex items-center h-6 w-6 bg-blue-500 text-white rounded-full" @click="setScale('mobile', -0.1)">
              <icon-minus clas="w-6 h-6 text-white" />
            </button>
            <span class="mx-2">
              {{ scaleRatios["mobile"] ? scaleRatioFormatter(scaleRatios["mobile"]) : "" }}
            </span>
            <button class="flex items-center h-6 w-6 bg-blue-500 text-white rounded-full" @click="setScale('mobile', 0.1)">
              <icon-plus clas="w-6 h-6 text-white" />
            </button>
          </div>
        </div>
        <button class="h-8 w-8 px-1 py-1 bg-blue-500 flex items-center text-center text-white rounded-full" @click="reloadIframes">
          <icon-refresh clas="w-6 h-6 text-white" />
        </button>
        <div class="flex items-center">
          <p class="font-medium">Desktop</p>
          <DesktopResolutionSelector class="mx-4" />
          <div class="flex items-center bg-gray-200 px-2 py-1 rounded-md">
            <button class="flex items-center h-6 w-6 bg-blue-500 text-white rounded-full" @click="setScale('desktop', -0.1)">
              <icon-minus clas="w-6 h-6 text-white" />
            </button>
            <span class="mx-2">
              {{ scaleRatios["desktop"] ? scaleRatioFormatter(scaleRatios["desktop"]) : "" }}
            </span>
            <button class="flex items-center h-6 w-6 bg-blue-500 text-white rounded-full" @click="setScale('desktop', 0.1)">
              <icon-plus clas="w-6 h-6  text-white" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex"
import DesktopResolutionSelector from "@/components/campaign-builder/DesktopResolutionSelector"
import MobileResolutionSelector from "@/components/campaign-builder/MobileResolutionSelector"
import IconRefresh from "@/icons/icon-refresh.svg"
import IconMinus from "@/icons/icon-minus.svg"
import IconPlus from "@/icons/icon-plus.svg"

export default {
  name: "Preview",
  components: { MobileResolutionSelector, DesktopResolutionSelector, IconRefresh, IconMinus, IconPlus },
  data() {
    return {
      scaleRatios: {},
      eventNotifierOptions: {
        position: "bottom-left",
        duration: 2000
      }
    }
  },
  computed: {
    ...mapGetters("preview", ["getPreviewDevicesLoading", "getStyleVariablesText"]),
    ...mapState("preview", ["previewDevices", "html"])
  },
  watch: {
    getStyleVariablesText() {
      if (!this.getPreviewDevicesLoading) {
        Object.keys(this.previewDevices).forEach((deviceKey) => this.setChangeableStyle(deviceKey))
      }
    },
    getPreviewDevicesLoading(newVal) {
      if (newVal === false) this.setRandomBackground()
    },
    $data: {
      handler: () => {},
      deep: true
    },
    previewDevices: {
      handler: function () {
        Object.keys(this.previewDevices).forEach((deviceKey) => this.load(deviceKey))
      },
      deep: true
    }
  },
  methods: {
    ...mapActions("preview", ["reloadIframes", "setBackgroundOfIframes"]),
    ...mapMutations("preview", ["addIframe", "setPreviewDevicesLoading", "setCurrentPage"]),
    scaleRatioFormatter: (val) => (val * 100).toFixed(2) + "%",
    setRandomBackground() {
      this.setBackgroundOfIframes("wisepops.com")
    },
    eventListenerInit(iframe) {
      iframe.contentWindow.addEventListener("maSubmit", this.eventListenerSubmit)
      iframe.contentWindow.addEventListener("maWheelOfFortuneSpun", this.eventListenerWheelOfFortuneSpun)
      iframe.contentWindow.addEventListener("maGiftPickerBoxOpen", this.eventListenerGiftPickerOpen)
      iframe.contentWindow.addEventListener("maClose", this.eventListenerClose)
      iframe.contentWindow.addEventListener("maUrl", this.eventListenerUrl)
      iframe.contentWindow.addEventListener("maWhatsapp", this.eventListenerWhatsapp)
      iframe.contentWindow.addEventListener("maGoalReached", this.eventListenerReached)
      iframe.contentWindow.addEventListener("maConversion", this.eventListenerConversion)
      iframe.contentWindow.addEventListener("maRejection", this.eventListenerRejection)
    },
    eventListenerGiftPickerOpen(e) {
      this.$toast.success(
        `Gift picker<br><br>Coupon text: ${e.detail.selectedText}<br>Coupon Code: ${e.detail.selectedCoupon}`,
        this.eventNotifierOptions
      )
    },
    eventListenerWheelOfFortuneSpun(e) {
      this.$toast.success(
        `Wheel of fortune spun<br><br>Coupon text: ${e.detail.selectedText}<br>Coupon Code: ${e.detail.selectedCoupon}`,
        this.eventNotifierOptions
      )
    },
    eventListenerReached() {
      this.$toast.success("Goal Reached", this.eventNotifierOptions)
    },
    eventListenerRejection() {
      this.$toast.success("Rejection", this.eventNotifierOptions)
    },
    eventListenerConversion() {
      this.$toast.success("Conversion", this.eventNotifierOptions)
    },
    eventListenerSubmit() {
      this.$toast.success(`Submit`, this.eventNotifierOptions)
    },
    eventListenerClose() {
      this.$toast.success("Closed", this.eventNotifierOptions)
    },
    eventListenerWhatsapp(data) {
      this.$toast.success(`Whatsapp Redirect | Phone Number: ${data.detail.number}`, this.eventNotifierOptions)
    },
    eventListenerUrl(data) {
      this.$toast.success(`Open ${data.detail.url}<br>New tab: ${data.detail.newTab === "true" ? "Yes" : "No"}`, this.eventNotifierOptions)
    },
    load(deviceKey) {
      this.setPreviewDevicesLoading({ device: deviceKey, status: true })
      const iframe = this.$refs[deviceKey][0]
      const device = this.previewDevices[deviceKey]
      iframe.contentWindow.osmEnvironmentIsPreview = true

      this.setChangeableStyle(deviceKey)
      this.addIframe({ [deviceKey]: iframe })
      this.setImagesStyle(deviceKey)

      const areaHeight = this.$refs["preview"].clientHeight - 40
      let scaleRatio = areaHeight / device.height
      scaleRatio = Math.floor(scaleRatio * 10) / 10
      if (scaleRatio > 1) scaleRatio = 1
      this.$set(this.scaleRatios, deviceKey, scaleRatio)

      iframe.style.width = device.width + "px"
      iframe.style.minWidth = device.width + "px"
      iframe.style.height = device.height + "px"
      iframe.style.minHeight = device.height + "px"
      iframe.style.transform = "scale(" + scaleRatio + ")"
      const my = -((device.height * (1 - scaleRatio)) / 2).toFixed(0)
      const mx = -((device.width * (1 - scaleRatio)) / 2).toFixed(0)
      iframe.style.margin = `${my}px ${mx}px`
      iframe.style.marginBottom = 0
      let interval = setInterval(
        function () {
          const doc = iframe.contentDocument || iframe.contentWindow.document
          if (doc.readyState === "complete" && doc.querySelector(".page")) {
            this.setPreviewDevicesLoading({ device: deviceKey, status: false })
            if (!this.getPreviewDevicesLoading) {
              clearInterval(interval)
              doc.addEventListener("osmPageIsChanged", (event) => {
                this.setCurrentPage(event.detail.page)
                this.reloadIframes()
              })
              this.eventListenerInit(iframe)
            }
          }
        }.bind(this),
        100
      )
    },
    setScale(deviceKey, param) {
      const iframe = this.$refs[deviceKey][0]
      const scaleRatio = parseFloat(this.scaleRatios[deviceKey]) + param
      this.$set(this.scaleRatios, deviceKey, scaleRatio)
      iframe.style.transform = "scale(" + scaleRatio + ")"
      const my = -((this.previewDevices[deviceKey].height * (1 - scaleRatio)) / 2).toFixed(0)
      const mx = -((this.previewDevices[deviceKey].width * (1 - scaleRatio)) / 2).toFixed(0)
      iframe.style.margin = `${my}px ${mx}px`
      iframe.style.marginBottom = 0
    },
    setChangeableStyle(deviceKey) {
      this.$refs[deviceKey][0].contentWindow.document.querySelector("style#osm-user").innerHTML = this.getStyleVariablesText
    },
    setImagesStyle(deviceKey) {
      /*
        This function analyzes style#osm-images and makes image styles inline.
        It is necessary because image style options must be inline to run system healthy and
        on saving html, these attributes are moving from inline styles to style#osm-images.
        So this actually returns back.
      */

      const doc = this.$refs[deviceKey][0].contentWindow.document
      const imageStyle = doc.querySelector("style#osm-images")
      const regexPattern = /#(.*?){(.*?)}/g
      if (imageStyle.innerHTML.split("#").length > 1) {
        if (deviceKey === "desktop") {
          const desktopImageStyle = imageStyle.innerHTML.split("@media (max-width: 959px){")[0]
          desktopImageStyle.match(regexPattern).forEach((imgCss) => {
            const imageSelector = imgCss.split("{")[0]
            doc.querySelector(imageSelector).style = imgCss.split("{")[1].replace("}", "")
          })
        } else if (deviceKey === "mobile") {
          const mobileImageStyle = imageStyle.innerHTML.split("@media (max-width: 959px){")[1]
          mobileImageStyle.match(regexPattern).forEach((imgCss) => {
            const imageSelector = imgCss.split("{")[0]
            doc.querySelector(imageSelector).style = imgCss.split("{")[1].replace("}", "")
          })
        }
      }
    }
  }
}
</script>
