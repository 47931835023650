<template>
  <div>
    <div v-for="(n, index) in images.length" :key="'btn' + index" class="image-select-with-preview">
      <UploadImage @upload="(url) => changeImage(index, url)">
        <span class="preview">
          <img :src="getImgSrcByIndex(index)" />
          <span>Change</span>
        </span>
      </UploadImage>
      <button v-if="!allowOnlyChange" class="remove" @click="removeImage(index)">Remove</button>
    </div>

    <div v-if="!allowOnlyChange" class="mt-8">
      <UploadImage class="cursor-pointer px-4 py-2 bg-blue-500 text-white rounded-md inline-block text-center" @upload="addNewImage">
        Add New Image
      </UploadImage>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import UploadImage from "@/components/Edit/UploadImage"
import randomId from "@/lib/utils/random-id"

export default {
  name: "Images",
  components: { UploadImage },
  props: {
    imagesClass: {
      required: true,
      type: String
    },
    allowOnlyChange: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      images: []
    }
  },
  computed: {
    ...mapGetters("preview", ["getIframeDocuments", "getPageSelector"])
  },
  methods: {
    fillDataImages() {
      this.images = Object.values(this.getIframeDocuments)[0].querySelectorAll(`${this.getPageSelector} .${this.imagesClass} img`)
    },
    removeImage(key) {
      Object.values(this.getIframeDocuments).forEach((doc) => {
        const owner = doc.querySelector(`${this.getPageSelector} .${this.imagesClass}`)
        const img = owner.querySelectorAll("img")[key]
        owner.removeChild(img)
      })
      this.fillDataImages()
    },
    changeImage(key, url) {
      Object.values(this.getIframeDocuments).forEach((doc) => {
        const owner = doc.querySelector(`${this.getPageSelector} .${this.imagesClass}`)
        const img = owner.querySelectorAll("img")[key]
        img.src = url
      })
      this.fillDataImages()
    },
    addNewImage(imageSrc) {
      const id = randomId()
      Object.values(this.getIframeDocuments).forEach((doc) => {
        const img = doc.createElement("img")
        img.setAttribute("id", id)
        img.setAttribute("src", imageSrc)
        const parent = doc.querySelector(`${this.getPageSelector} .${this.imagesClass}`)
        parent.appendChild(img)
        this.fillDataImages()
      })
    },
    getImgSrcByIndex(index) {
      const images = this.getIframeDocuments[0].querySelectorAll(`${this.getPageSelector} .${this.imagesClass} img`)
      return images[index].src
    }
  },

  mounted() {
    this.fillDataImages()
  }
}
</script>
