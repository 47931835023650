<template>
  <div>
    <div v-if="showTitle" class="flex items-center justify-between px-4 py-2 bg-gray-200 rounded-md mb-4">
      <div class="font-medium">{{ title }}</div>
      <button v-if="button === false" class="text-white rounded-md flex items-center px-1 py-1 bg-blue-500" @click="add">
        <icon-plus class="w-6 h-6 text-white" />
      </button>
      <button v-else class="text-white rounded-md flex items-center px-1 py-1 bg-red-500" @click="remove">
        <icon-x class="w-6 h-6 text-white" />
      </button>
    </div>
    <div v-if="button !== false">
      <div v-if="innerEditable">
        <mini-editor :link="false" :selector="`${buttonAreaSelector} .${buttonClass}`" />
      </div>
      <div class="my-2">
        <label class="mr-4">Count As:</label>
        <dropdown-select v-model="buttonActions.countAs">
          <option value="none">None</option>
          <option value="conversion">Conversion</option>
          <option value="rejection">Rejection</option>
        </dropdown-select>
      </div>
      <div class="button-actions">
        <Checkbox v-model="buttonActions.goalReached.status">Campaign Goal Reached</Checkbox>
        <Checkbox v-model="buttonActions.close.status">Close Campaign</Checkbox>
        <Checkbox v-model="buttonActions.openUrl.status" :disabled="buttonActions.whatsapp.status">Open URL</Checkbox>
        <div v-if="buttonActions.openUrl.status" class="ml-4 mb-6">
          <label class="flex items-center">
            <span class="mr-4">URL</span>
            <text-input v-model="buttonActions.openUrl.url" padding="small" />
          </label>
          <Checkbox v-model="buttonActions.openUrl.newTab">Open the page in a new tab</Checkbox>
        </div>
        <Checkbox v-model="buttonActions.whatsapp.status" :disabled="buttonActions.openUrl.status">Redirect To WhatsApp </Checkbox>

        <div v-if="buttonActions.whatsapp.status" class="ml-4 mb-6">
          <label class="flex items-center">
            <span class="mr-4">Number</span>
            <text-input v-model="buttonActions.whatsapp.number" padding="small" />
          </label>
        </div>
        <Checkbox v-model="buttonActions.submit.status">Open Next Page / Submit Form</Checkbox>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import Checkbox from "@/components/common/Checkbox"
import MiniEditor from "@/components/campaign-builder/content-changers/MiniEditor"
import IconX from "@/icons/icon-x.svg"
import IconPlus from "@/icons/icon-plus.svg"
import DropdownSelect from "@/components/common/DropdownSelect"
import TextInput from "@/components/common/TextInput"

export default {
  name: "SingleButton",
  components: { TextInput, DropdownSelect, MiniEditor, Checkbox, IconX, IconPlus },
  props: {
    buttonAreaSelector: String,
    buttonClass: String,
    title: String,
    showTitle: {
      type: Boolean,
      default: true
    },
    innerEditable: {
      type: Boolean,
      default: true
    },
    order: {
      default: "1"
    }
  },
  data() {
    return {
      button: false,
      buttonActions: {
        openUrl: {
          status: false,
          url: "https://",
          newTab: false
        },
        whatsapp: {
          status: false,
          number: ""
        },
        submit: {
          status: false
        },
        goalReached: {
          status: false
        },
        close: {
          status: false
        },
        countAs: "none"
      }
    }
  },
  computed: mapGetters("preview", ["getIframeDocuments", "getPageSelector"]),
  watch: {
    buttonActions: {
      handler: "buttonWatchHandler",
      deep: true
    }
  },
  methods: {
    buttonWatchHandler() {
      this.setButtonData()
    },
    analyzeButtons() {
      const button = this.getIframeDocuments[0].querySelector(`${this.buttonAreaSelector} .${this.buttonClass}`)
      if (!button) {
        this.button = false
        return
      }
      this.button = button
      if (button.dataset["close"]) {
        this.buttonActions.close.status = true
      }
      if (button.dataset["whatsapp"]) {
        this.buttonActions.whatsapp.status = true
        this.buttonActions.whatsapp.number = button.dataset["whatsapp"]
      }
      if (button.dataset["url"]) {
        this.buttonActions.openUrl.status = true
        this.buttonActions.openUrl.url = button.dataset["url"]
        if (button.dataset["newTab"]) {
          this.buttonActions.openUrl.newTab = true
        }
      }
      if (button.dataset["submit"]) {
        this.buttonActions.submit.status = true
      }
      if (button.dataset["goalReached"]) {
        this.buttonActions.goalReached.status = true
      }
      if (button.dataset["countAs"]) {
        this.buttonActions.countAs = button.dataset["countAs"]
      }
    },
    remove() {
      Object.values(this.getIframeDocuments).forEach((doc) => {
        const button = doc.querySelector(`${this.buttonAreaSelector} .${this.buttonClass}`)
        const owner = button.parentElement
        owner.removeChild(button)
      })
      this.analyzeButtons()
    },
    add() {
      Object.values(this.getIframeDocuments).forEach((doc) => {
        const button = doc.createElement("button")
        button.classList.add(this.buttonClass)
        button.dataset.countAs = "none"
        if (this.order === "1") {
          doc.querySelector(`${this.buttonAreaSelector}`).prepend(button)
        } else {
          doc.querySelector(`${this.buttonAreaSelector}`).appendChild(button)
        }
      })
      this.analyzeButtons()
    },
    setButtonData() {
      const attrs = ["data-url", "data-new-tab", "data-submit", "data-goal-reached", "data-count-as", "data-whatsapp", "data-close"]
      Object.values(this.getIframeDocuments).forEach((doc) => {
        const button = doc.querySelector(`${this.buttonAreaSelector} .${this.buttonClass}`)
        if (!button) {
          return
        }
        attrs.forEach((attr) => button.removeAttribute(attr))
        if (this.buttonActions.openUrl.status === true) {
          button.setAttribute("data-url", this.buttonActions.openUrl.url)
          button.setAttribute("data-new-tab", this.buttonActions.openUrl.newTab)
        }
        if (this.buttonActions.whatsapp.status === true) {
          button.setAttribute("data-whatsapp", this.buttonActions.whatsapp.number)
        }
        if (this.buttonActions.submit.status === true) {
          button.setAttribute("data-submit", this.buttonActions.submit.status)
        }
        if (this.buttonActions.close.status === true) {
          button.setAttribute("data-close", this.buttonActions.close.status)
        }
        if (this.buttonActions.goalReached.status === true) {
          button.setAttribute("data-goal-reached", this.buttonActions.goalReached.status)
        }
        button.setAttribute("data-count-as", this.buttonActions.countAs)
      })
    }
  },
  created() {
    this.analyzeButtons()
    this.setButtonData()
  }
}
</script>

<style scoped></style>
