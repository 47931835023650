class htmlExporter {
  static getHtml(iframes, documentElement) {
    const parentId = documentElement.querySelector("[data-parent]").id
    let imgStyle = ``
    imgStyle += "@media (min-width: 960px){"
    const { mobile, desktop } = iframes
    desktop.contentWindow.document.querySelectorAll("img").forEach((img) => {
      if (img.getAttribute("style")) {
        imgStyle += `#${parentId} #${img.getAttribute("id")}{${img.getAttribute("style")}}`
      }
    })
    imgStyle += "}"
    imgStyle += "@media (max-width: 959px){"
    mobile.contentWindow.document.querySelectorAll("img").forEach((img) => {
      if (img.getAttribute("style")) {
        imgStyle += `#${parentId} #${img.getAttribute("id")}{${img.getAttribute("style")}}`
      }
    })
    imgStyle += "}"
    documentElement.documentElement.querySelector("style#osm-images").innerHTML = imgStyle
    documentElement.documentElement.querySelectorAll("img").forEach((img) => img.removeAttribute("style"))

    /* All pages' display must be none on initialize */
    documentElement.querySelectorAll(".page").forEach((page) => (page.style.display = "none"))
    documentElement.querySelector("body").removeAttribute("style")

    /* SET CURRENT PAGE TO 1 */
    const pageInitializeScript = documentElement.documentElement.querySelector("script[data-osm-function='setOsmInitializePage']")
    pageInitializeScript.innerHTML = `window.osmInitializePage = 1;`

    const intlUtil = documentElement.querySelector(
      `script[src="https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.min.js"]`
    )
    if (intlUtil) {
      intlUtil.remove()
    }
    documentElement.querySelectorAll(`script[class='osm-remote-script']`).forEach((script) => {
      script.remove()
    })
    return documentElement.documentElement.innerHTML
  }

  static getFormFields(documentElement) {
    const formFields = {}
    documentElement.querySelectorAll(".page").forEach((page) => {
      const form = page.querySelector("form")
      if (!form) return
      form
        .querySelectorAll(`input[type=text], input[type=email], input[type=number], input[type=tel], input[type=url], input[type=checkbox]`)
        .forEach((input) => {
          formFields[input.name] = {
            type: input.type,
            required: input.required
          }
        })
      form.querySelectorAll("textarea").forEach((textarea) => {
        formFields[textarea.name] = {
          type: "textarea",
          required: textarea.required
        }
      })
      form.querySelectorAll("select").forEach((select) => {
        const fields = []
        select.querySelectorAll("option").forEach((opt) => fields.push(opt.value))
        formFields[select.name] = {
          type: "select",
          required: select.required,
          options: fields
        }
      })
      form.querySelectorAll("input[type=radio]").forEach((radio) => {
        if (!formFields[radio.name]) {
          formFields[radio.name] = {
            type: "radio",
            required: radio.required,
            options: []
          }
        }
        formFields[radio.name].options.push(radio.value)
      })
    })
    return formFields
  }
}

export default htmlExporter
