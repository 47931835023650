<template>
  <div>
    <settings-title>{{ getTitle }}</settings-title>
    <template v-if="setAreaTopic === 'content-sections'">
      <div v-for="(data, key) in wheelData" :key="key">
        <SettingsElement>
          <template #title> Slice #{{ key + 1 }}</template>
          <template #body>
            <label class="flex items-center mb-2">
              <span class="w-20 mr-4">Text:</span>
              <TextInput :value="data.text" type="text" @input="(e) => setContent(e, key, 'text')" />
            </label>
            <label class="flex items-center mb-2">
              <span class="w-20 mr-4">Coupon:</span>
              <TextInput :value="data.coupon" type="text" @input="(e) => setContent(e, key, 'coupon')" />
            </label>
            <label class="flex items-center mb-2">
              <span class="w-20 mr-4">Weight:</span>
              <TextInput :value="data.weight" type="text" @input="(e) => setContent(e, key, 'weight')" />
            </label>
          </template>
        </SettingsElement>
      </div>
    </template>

    <MiniEditor v-if="setAreaTopic === 'content-title'" :selector="`${getPageSelector} .title`" />
    <MiniEditor v-if="setAreaTopic === 'content-subtitle'" :selector="`${getPageSelector} .subtitle`" />
    <MiniEditor v-if="setAreaTopic === 'content-description'" :selector="`${getPageSelector} .description`" />
    <Images v-if="setAreaTopic === 'content-images'" images-class="images" />
    <ContentFormFields v-if="setAreaTopic === 'content-form'" />
    <DoubleButtons
      v-if="setAreaTopic === 'content-buttons'"
      :button-area-selector="`${getPageSelector} .form-and-buttons .buttons`"
      primary-button-class="primary"
      secondary-button-class="secondary"
    />
    <MiniEditor v-if="setAreaTopic === 'content-notes'" :selector="`${getPageSelector} .notes`" />
    <template v-if="setAreaTopic === 'content-custom-actions'">
      <Checkbox v-if="customActionsShowOverlayClose" v-model="customActionsOverlayClose">Close Widget When Click To Overlay </Checkbox>
      <Checkbox v-model="customActionsEscClose">Close Widget When Pressed "ESC"</Checkbox>
    </template>
  </div>
</template>
<script>
import MiniEditor from "@/components/campaign-builder/content-changers/MiniEditor"
import { mapGetters } from "vuex"
import Images from "@/components/campaign-builder/content-changers/Images"
import ContentFormFields from "@/components/campaign-builder/content-changers/general-form-fields/ContentFormFields"
import SettingsTitle from "@/components/campaign-builder/widget-settings/SettingsTitle"
import DoubleButtons from "@/components/campaign-builder/content-changers/DoubleButtons"
import Checkbox from "@/components/common/Checkbox"
import SettingsElement from "@/components/campaign-builder/widget-settings/SettingsElement"
import TextInput from "@/components/common/TextInput"

export default {
  name: "WheelOfFortuneContents",
  components: {
    TextInput,
    SettingsElement,
    Checkbox,
    DoubleButtons,
    SettingsTitle,
    ContentFormFields,
    Images,
    MiniEditor
  },
  data() {
    return {
      customActionsOverlayClose: false,
      customActionsEscClose: false,
      customActionsShowOverlayClose: true,
      wheelData: []
    }
  },
  computed: {
    ...mapGetters("preview", ["getPageSelector", "getIframeDocuments"]),
    setAreaTopic() {
      return this.$store.state.preview.setAreaTopic
    },
    getTitle() {
      return {
        "content-sections": "Slices",
        "content-title": "Title",
        "content-subtitle": "Subtitle",
        "content-description": "Description",
        "content-images": "Images",
        "content-form": "Form",
        "content-buttons": "Buttons",
        "content-notes": "Notes",
        "content-custom-actions": "Custom Actions"
      }[this.setAreaTopic]
    }
  },
  watch: {
    overlayClose(newV) {
      this.getIframeDocuments.forEach((doc) => {
        if (newV) doc.querySelector(`${this.getPageSelector} .overlay`).setAttribute("data-close", "true")
        else doc.querySelector(`${this.getPageSelector} .overlay`).removeAttribute("data-close")
      })
    },
    escClose(newV) {
      this.getIframeDocuments.forEach((doc) => {
        if (newV) doc.querySelector(`${this.getPageSelector}`).setAttribute("data-esc-close", "true")
        else doc.querySelector(`${this.getPageSelector}`).removeAttribute("data-esc-close")
      })
    }
  },
  methods: {
    setContent(value, key, dataType) {
      this.wheelData[key][dataType] = value
      this.getIframeDocuments.forEach((doc) => {
        const element = doc.querySelectorAll(".wheel .labels text")[key]
        if (dataType === "text") element.innerHTML = value
        else if (dataType === "coupon") element.dataset.coupon = value
        else if (dataType === "weight") element.dataset.weight = value
      })
    }
  },
  created() {
    if (this.getIframeDocuments[0].querySelector(`${this.getPageSelector} .overlay`).getAttribute("data-close")) {
      this.customActionsOverlayClose = true
    }
    if (this.getIframeDocuments[0].querySelector(`${this.getPageSelector}`).getAttribute("data-esc-close")) {
      this.customActionsEscClose = true
    }
    const textElements = this.getIframeDocuments[0].querySelectorAll(".wheel .labels text")
    this.wheelData = []
    textElements.forEach((i) =>
      this.wheelData.push({
        text: i.innerHTML,
        coupon: i.dataset.coupon,
        weight: i.dataset.weight
      })
    )
  }
}
</script>
