<template>
  <label :class="multiline ? 'multiline' : ''" class="input-with-label">
    <span :class="textClasses"><slot /></span>
    <input :value="value" class="input" type="text" @input="$emit('input', $event.target.value)" />
  </label>
</template>

<script>
export default {
  name: "InputWithLabel",
  props: {
    value: {
      required: true
    },
    multiline: {
      default: false
    },
    textClasses: String
  }
}
</script>

<style lang="scss" scoped>
.input-with-label {
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
  display: flex;
  align-items: center;

  span {
    margin-right: 12px;
  }

  .input {
    border: 1px solid var(--light-gray-2);
    width: 100%;
    padding: 4px 8px;
    border-radius: 2px;
  }

  &.multiline {
    span {
      display: block;
      margin-right: 0;
      margin-bottom: 0.625rem;
    }
  }
}
</style>
