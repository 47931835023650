<template>
  <div>
    <div class="flex items-center my-1">
      <span class="w-16">Name</span>
      <div class="flex-1">
        <text-input v-model="name" padding="small" @input="changeName" />
      </div>
    </div>
    <Checkbox v-model="isRequired" @input="changeIsRequired">Required</Checkbox>

    <div class="options">
      <h3 class="mt-4 mb-2 font-medium">Options</h3>
      <draggable handle=".handle" @end="dragEnd">
        <div v-for="(option, key) in options" :key="key" class="mb-2 border border-gray-100 rounded-md pl-8 pr-6 py-2 flex relative">
          <button class="handle absolute -left-2 top-1/2 transform -translate-y-1/2 px-1 py-2 rounded-md bg-blue-600 text-white">
            <icon-bars class="w-5 h-5" />
          </button>
          <button @click="removeOption(key)">
            <icon-x class="w-5 h-5 absolute -top-0 -right-0" />
          </button>
          <text-input :value="option" @input="(text) => setOption(key, text)" />
        </div>
      </draggable>
      <button class="mx-auto block" @click="addOption">
        <icon-plus class="w-6 h-6 mx-auto bg-blue-600 text-white rounded-full shadow-md" />
      </button>
    </div>
  </div>
</template>

<script>
import randomId from "@/lib/utils/random-id"
import draggable from "vuedraggable"
import Checkbox from "@/components/common/Checkbox"
import TextInput from "@/components/common/TextInput"
import IconBars from "@/icons/icon-bars.svg"
import IconPlus from "@/icons/icon-plus.svg"
import IconX from "@/icons/icon-x.svg"

export default {
  name: "Radio",
  props: ["elementKey", "eachFormData", "element"],
  components: {
    TextInput,
    Checkbox,
    draggable,
    IconBars,
    IconX,
    IconPlus
  },
  data() {
    return {
      isRequired: "",
      name: "",
      options: []
    }
  },
  methods: {
    changeName() {
      this.eachFormData((f) => {
        f[this.elementKey]["field"].querySelectorAll("input").forEach((input) => (input.name = this.name))
      })
    },
    setOption(key, value) {
      this.eachFormData((f) => {
        const input = f[this.elementKey]["field"].querySelectorAll("input")[key]
        const span = input.parentElement.querySelector("span")
        span.innerText = value
        input.value = value
      })
    },
    fillOptions() {
      this.options = []
      this.element.querySelectorAll("input").forEach((input) => this.options.push(input.value))
    },
    addOption() {
      const randId = randomId()
      const i = this.options.length + 1
      const html = `<label for="${randId}" class="not-parent-label">
                    <input id="${randId}" required="true" name="${this.name}" value="Option ${i}" class="user-input" type="radio">
                    <span>Option ${i}</span>
                  </label>`
      this.eachFormData((f) => {
        f[this.elementKey]["field"].innerHTML = f[this.elementKey]["field"].innerHTML + html
      })
      this.fillOptions()
    },
    removeOption(key) {
      this.eachFormData((f) => f[this.elementKey]["field"].querySelectorAll("label")[key].remove())
      this.fillOptions()
    },
    dragEnd(event) {
      const { newIndex, oldIndex } = event
      this.eachFormData((f) => {
        const parent = f[this.elementKey]["field"]
        const fields = parent.querySelectorAll("label")
        const newFields = [...fields]

        const tmp = newFields[oldIndex]
        newFields.splice(oldIndex, 1)
        newFields.splice(newIndex, 0, tmp)

        parent.innerHTML = null
        newFields.forEach((field) => parent.appendChild(field))
      })
    },
    changeIsRequired() {
      this.eachFormData((f) => {
        f[this.elementKey]["field"].querySelectorAll("input").forEach((inp) => (inp.required = this.isRequired))
      })
    }
  },
  mounted() {
    this.name = this.element.querySelector("input").name
    this.isRequired = this.element.querySelector("input").required
    this.fillOptions()
  }
}
</script>
