<template>
  <div>
    <settings-title>{{ getTitle }}</settings-title>
    <MiniEditor v-if="setAreaTopic === 'content-message'" :selector="`${getPageSelector} .content .message`" />
    <ContentButton v-if="setAreaTopic === 'content-button'" />
    <Images v-if="setAreaTopic === 'content-images'" images-class="images" />
  </div>
</template>
<script>
import MiniEditor from "@/components/campaign-builder/content-changers/MiniEditor"
import { mapGetters } from "vuex"
import ContentButton from "@/components/campaign-builder/widget-settings/flat-bar/Content/ContentButton"
import Images from "@/components/campaign-builder/content-changers/Images"
import SettingsTitle from "@/components/campaign-builder/widget-settings/SettingsTitle"

export default {
  name: "Contents",
  components: {
    SettingsTitle,
    Images,
    ContentButton,
    MiniEditor
  },
  computed: {
    ...mapGetters("preview", ["getPageSelector"]),
    setAreaTopic() {
      return this.$store.state.preview.setAreaTopic
    },
    getTitle() {
      return {
        "content-message": "Message",
        "content-button": "Button",
        "content-images": "Images"
      }[this.setAreaTopic]
    }
  }
}
</script>
