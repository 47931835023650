<template>
  <div class="settings-wrapper h-full flex items-center border-b border-gray-200 w-full">
    <div class="text-gray-800 w-full h-full overflow-y-auto">
      <div class="px-4 py-2 rounded-lg settings-tab-selector-wrapper">
        <div class="flex items-center justify-between h-full bg-gray-100 rounded-lg border overflow-hidden border-gray-200">
          <button
            :class="activeTab === 'content' ? 'font-medium  bg-blue-600 text-white' : ''"
            class="uppercase text-sm tracking-wider font-bold text-center w-full h-full rounded-none"
            @click="activeTab = 'content'"
          >
            Content
          </button>
          <button
            :class="activeTab === 'appearance' ? 'font-medium  bg-blue-600 text-white' : ''"
            class="uppercase text-sm tracking-wider font-bold text-center w-full h-full rounded-none"
            @click="activeTab = 'appearance'"
          >
            Appearance
          </button>
        </div>
      </div>
      <aside class="px-4 mt-8">
        <div v-if="activeTab === 'content'" :key="contentKey">
          <slot name="content" />
        </div>
        <div v-if="activeTab === 'appearance'" :key="appearanceKey">
          <slot name="appearance" />
        </div>
      </aside>
    </div>
  </div>
</template>

<script>
import randomId from "@/lib/utils/random-id"

export default {
  name: "SettingsContentAppearance",
  data() {
    return {
      activeTab: "content",
      contentKey: "",
      appearanceKey: ""
    }
  },
  watch: {
    activeTab() {
      this.contentKey = randomId()
      this.appearanceKey = randomId()
    }
  }
}
</script>

<style scoped></style>
