<template>
  <button class="add-new-field relative mx-auto block h-auto">
    <span class="px-4 py-2 flex items-center mx-auto bg-blue-600 text-white rounded-md shadow-md">
      <span>New Form Field</span>
      <icon-plus class="w-6 h-6" />
    </span>
    <div
      class="hidden absolute add-new-field-area top-8 px-6 py-2 rounded-lg shadow-md border-2 border-gray-200 left-1/2 transform -translate-x-1/2 bg-gray-100"
    >
      <button @click="addField('email')">Email</button>
      <button @click="addField('checkbox')">Checkbox</button>
      <button @click="addField('select')">Dropdown List</button>
      <button @click="addField('input-text')">Single Line Input</button>
      <button @click="addField('input-url')">URL Input</button>
      <button @click="addField('input-number')">Number Input</button>
      <button @click="addField('input-date')">Date Input</button>
      <button @click="addField('input-tel')">Phone Number Input</button>
      <button @click="addField('text-area')">Multi Line Input</button>
      <button @click="addField('rating')">Rating</button>
      <button @click="addField('radios')">Radios</button>
    </div>
  </button>
</template>

<script>
import FormElementHelper from "@/components/campaign-builder/content-changers/general-form-fields/form-fields/FormElementHelper"
import { mapActions, mapGetters } from "vuex"
import IconPlus from "@/icons/icon-plus.svg"

export default {
  name: "AddNewField",
  components: { IconPlus },
  computed: mapGetters("preview", ["getPageSelector", "getIframeDocuments"]),
  methods: {
    ...mapActions("preview", ["reloadIframes"]),
    addField(type) {
      const formElements = new FormElementHelper(this.getIframeDocuments, `${this.getPageSelector} form`)
      formElements.addField(type)
      this.$emit("added")
      this.reloadIframes()
    }
  }
}
</script>

<style lang="scss" scoped>
.add-new-field {
  &:hover .add-new-field-area {
    display: block;
  }

  .add-new-field-area {
    button {
      @apply py-2 px-4 w-full block text-left whitespace-nowrap rounded;
      &:hover {
        @apply bg-blue-600 text-white;
      }
    }
  }
}
</style>
