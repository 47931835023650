const formFields = [
  {
    selector: ".radios",
    type: "radios",
    name: "Radios",
    nameSelector: ".radios input",
    component: "Radio"
  },
  {
    selector: ".user-input-rating",
    type: "rating",
    name: "Rating",
    nameSelector: ".user-input-rating input",
    component: "Rating"
  },
  {
    selector: ".osm-input-tel",
    type: "tel",
    name: "Phone Number",
    nameSelector: ".osm-input-tel",
    component: "InputTel"
  },
  {
    selector: 'input[type="text"]',
    type: "text",
    name: "Single Line Text",
    nameSelector: 'input[type="text"]',
    component: "InputText"
  },
  {
    selector: ".osm-input-date",
    type: "date",
    name: "Date",
    nameSelector: ".osm-input-date",
    component: "InputText"
  },
  {
    selector: 'input[type="url"]',
    type: "url",
    name: "URL",
    nameSelector: 'input[type="url"]',
    component: "InputText"
  },
  {
    selector: 'input[type="number"]',
    type: "number",
    name: "Number",
    nameSelector: 'input[type="number"]',
    component: "InputText"
  },
  {
    selector: 'input[type="email"]',
    type: "email",
    name: "Email",
    nameSelector: 'input[type="email"]',
    component: "Email"
  },
  {
    selector: "textarea",
    type: "textarea",
    name: "Multi Line Text",
    nameSelector: "textarea",
    component: "Textarea"
  },
  {
    selector: 'input[type="checkbox"]',
    type: "checkbox",
    name: "Checkbox",
    nameSelector: 'input[type="checkbox"]',
    component: "Checkbox"
  },
  {
    selector: "select",
    type: "select",
    name: "Dropdown Selector",
    nameSelector: "select",
    component: "Select"
  }
]
export default formFields
