<template>
  <div>
    <div>
      <label for="placeholder">Placeholder:</label>
      <input id="placeholder" :value="element.placeholder" type="text" @input="changePlaceHolder" />
    </div>
    <Checkbox v-model="isRequired" @change="changeIsRequired"> Required</Checkbox>
  </div>
</template>

<script>
import Checkbox from "@/components/common/Checkbox"

export default {
  name: "Textarea",
  props: ["element", "elementKey", "eachFormData"],
  components: { Checkbox },
  data() {
    return {
      isRequired: ""
    }
  },
  methods: {
    changePlaceHolder(event) {
      this.eachFormData((f) => {
        f[this.elementKey]["field"].placeholder = event.target.value
      })
    },
    changeIsRequired(event) {
      this.eachFormData((f) => {
        f[this.elementKey]["field"].required = event.target.checked
      })
    }
  },
  mounted() {
    this.isRequired = this.element.required
  }
}
</script>
