const settingsMixin = {
  computed: {
    activeSettings: {
      get() {
        return this.$store.state.preview.activeSettings
      },
      set(value) {
        this.$store.state.preview.activeSettings = value
      }
    },
    isSetAreaActive: {
      get() {
        return this.$store.state.preview.isSetAreaActive
      },
      set(value) {
        this.$store.state.preview.isSetAreaActive = value
      }
    },
    setAreaTopic: {
      get() {
        return this.$store.state.preview.setAreaTopic
      },
      set(value) {
        this.$store.state.preview.setAreaTopic = value
      }
    }
  },
  methods: {
    activateSetArea(topic) {
      this.isSetAreaActive = true
      this.setAreaTopic = topic
    }
  }
}

export default settingsMixin
