<template>
  <input-with-unit v-if="width" v-model="width" :alternative-units="['px', 'vw', '%']" size="small" @updated="set" />
</template>

<script>
import { mapGetters } from "vuex"
import parseUnit from "@/lib/utils/parse-unit"
import imageToolMixin from "@/mixins/images/image-tool-mixin"
import InputWithUnit from "@/components/common/InputWithUnit"

export default {
  name: "ImageHeight",
  components: { InputWithUnit },
  mixins: [imageToolMixin],
  data() {
    return {
      width: null
    }
  },
  computed: {
    ...mapGetters("preview", ["getCurrentStyleVariables"])
  },
  methods: {
    fillDataFromImage() {
      const { unit, value } = parseUnit(this.img.style.width)
      this.width = { unit, value }
    },
    set() {
      this.img.style["width"] = this.width.value + this.width.unit
    }
  },
  mounted() {
    const styleVars = this.getCurrentStyleVariables.style[this.env]
    if (!this.img.style.width) this.img.style.width = styleVars["--image-width"]
    this.fillDataFromImage()
  }
}
</script>
