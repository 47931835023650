<template>
  <div>
    <settings-title>{{ getTitle }}</settings-title>

    <template v-if="setAreaTopic === 'content-sender'">
      <SettingsElement>
        <template #title>Sender Name</template>
        <template #body>
          <MiniEditor :no-settings="true" :selector="`${getPageSelector} .top-bar .name`" />
        </template>
      </SettingsElement>

      <SettingsElement>
        <template #title>Sender Picture</template>
        <template #body>
          <Images :allow-only-change="true" images-class="picture" />
        </template>
      </SettingsElement>
    </template>

    <Messages v-if="setAreaTopic === 'content-messages'" />
    <DoubleButtons
      v-if="setAreaTopic === 'content-buttons'"
      :button-area-selector="`${getPageSelector} .action-buttons`"
      primary-button-class="primary"
      secondary-button-class="secondary"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex"
import Messages from "@/components/campaign-builder/widget-settings/whatsapp/Contents/Messages"
import SettingsTitle from "@/components/campaign-builder/widget-settings/SettingsTitle"
import DoubleButtons from "@/components/campaign-builder/content-changers/DoubleButtons"
import MiniEditor from "@/components/campaign-builder/content-changers/MiniEditor"
import Images from "@/components/campaign-builder/content-changers/Images"
import SettingsElement from "@/components/campaign-builder/widget-settings/SettingsElement"

export default {
  name: "WhatsappContents",
  components: {
    SettingsElement,
    Images,
    MiniEditor,
    DoubleButtons,
    SettingsTitle,
    Messages
  },
  computed: {
    ...mapGetters("preview", ["getPageSelector"]),
    setAreaTopic() {
      return this.$store.state.preview.setAreaTopic
    },
    getTitle() {
      return {
        "content-sender": "Sender",
        "content-messages": "Messages",
        "content-buttons": "Buttons"
      }[this.setAreaTopic]
    }
  }
}
</script>
