<template>
  <div>
    <input-with-label v-model="name" @input="changeName">Name</input-with-label>
    <checkbox v-model="isRequired" @input="changeIsRequired">Required</checkbox>
  </div>
</template>

<script>
import InputWithLabel from "@/components/common/InputWithLabel"
import Checkbox from "@/components/common/Checkbox"

export default {
  name: "Rating",
  components: { Checkbox, InputWithLabel },
  props: ["elementKey", "eachFormData", "element"],
  data() {
    return {
      isRequired: "",
      name: ""
    }
  },
  methods: {
    changeName() {
      const newName = this.name
      this.eachFormData((f) => {
        const inputs = f[this.elementKey]["field"].querySelectorAll("input")
        const labels = f[this.elementKey]["field"].querySelectorAll("label")
        inputs.forEach((input, k) => {
          input.name = newName
          const id = newName + (5 - k).toString()
          input.id = id
          labels[k].htmlFor = id
        })
      })
    },
    changeIsRequired() {
      this.eachFormData((f) => {
        const inputs = f[this.elementKey]["field"].querySelectorAll("input")
        inputs.forEach((input) => {
          input.required = this.isRequired
        })
      })
    }
  },
  mounted() {
    this.isRequired = this.element.querySelector("input").required
    this.name = this.element.querySelector("input").name
  }
}
</script>
