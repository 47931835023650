<template>
  <div class="simple-editor">
    <div ref="editorNode" class="editor-node"></div>
  </div>
</template>

<script>
import Quill from "quill"
import { mapGetters } from "vuex"
import { fontList } from "@/lib/utils/google-font"
import randomId from "@/lib/utils/random-id"

const fontFamilies = fontList.map((i) => i.replaceAll(" ", "-"))
fontFamilies.unshift(false)
const lineHeightSizes = [
  false,
  "0.80",
  "0.85",
  "0.90",
  "0.95",
  "1.00",
  "1.05",
  "1.10",
  "1.15",
  "1.20",
  "1.25",
  "1.30",
  "1.35",
  "1.40",
  "1.45",
  "1.50",
  "1.60",
  "1.70",
  "1.80",
  "1.90",
  "2.00",
  "2.50",
  "3.00",
  "3.50",
  "4.00",
  "4.50",
  "5.00"
]
export default {
  props: {
    selector: {
      type: String,
      required: false
    },
    value: {
      type: String,
      required: false
    },
    link: {
      type: Boolean,
      default: true
    },
    fontWeight: {
      type: Boolean,
      default: true
    },
    fontFamilies: {
      type: Boolean,
      default: true
    },
    lineHeight: {
      type: Boolean,
      default: true
    },
    align: {
      type: Boolean,
      default: true
    },
    siu: {
      type: Boolean,
      default: true
    },
    noSettings: {
      type: Boolean,
      default: false
    },
    color: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      pickerId: "",
      editorContent: null,
      editorInstance: null,
      editorOpts: {
        modules: {
          toolbar: {
            container: []
          }
        },
        theme: "snow"
      }
    }
  },
  watch: {
    editorContent(newVal) {
      if (newVal !== this.editorContent) {
        this.editorInstance.pasteHTML(newVal)
      }
    }
  },
  computed: mapGetters("preview", ["getIframeDocuments"]),
  mounted() {
    this.pickerId = randomId()
    if (!this.noSettings) {
      if (this.fontFamilies) this.editorOpts.modules.toolbar.container.push([{ "font-family": fontFamilies }])
      if (this.fontWeight)
        this.editorOpts.modules.toolbar.container.push([{ "font-weight": ["300", false, "500", "600", "700", "800", "900"] }])
      if (this.color) this.editorOpts.modules.toolbar.container.push(["color"])
      if (this.lineHeight) this.editorOpts.modules.toolbar.container.push([{ "line-height": lineHeightSizes }])
      if (this.align) this.editorOpts.modules.toolbar.container.push([{ align: ["", "center", "right"] }])
      if (this.siu) this.editorOpts.modules.toolbar.container.push(["italic", "underline", "strike"])
      if (this.link) this.editorOpts.modules.toolbar.container.push(["link"])
    } else {
      this.editorOpts.modules.toolbar = false
    }
    this.initializeEditor()
  },

  beforeDestroy() {
    this.editorInstance.off("text-change")
  },
  methods: {
    initializeEditor() {
      if (typeof this.selector !== "undefined") {
        this.editorContent = this.getIframeDocuments[0].querySelector(this.selector).innerHTML
      } else if (typeof this.value !== "undefined") {
        this.editorContent = this.value
      } else {
        throw new DOMException("Editor error!")
      }
      let Parchment = Quill.import("parchment")
      let FontWeightStyle = new Parchment.Attributor.Style("font-weight", "font-weight", {
        scope: Parchment.Scope.INLINE_ATTRIBUTE,
        whitelist: ["300", false, "500", "600", "700", "800", "900"]
      })
      let LineHeightStyle = new Parchment.Attributor.Style("line-height", "line-height", {
        scope: Parchment.Scope.INLINE,
        whitelist: lineHeightSizes
      })
      let FontFamilyStyle = new Parchment.Attributor.Class("font-family", "font-family", {
        scope: Parchment.Scope.INLINE,
        whitelist: fontFamilies
      })
      Quill.register(
        {
          "formats/font-family": FontFamilyStyle,
          "formats/font-weight": FontWeightStyle,
          "formats/line-height": LineHeightStyle
        },
        true
      )
      this.editorInstance = new Quill(this.$refs.editorNode, this.editorOpts)
      this.editorInstance.root.innerHTML = this.editorContent.trim()
      this.editorInstance.on("text-change", this.setEditorContent)

      this.editorInstance.on(
        "text-change",
        function (delta) {
          delta.ops.forEach(
            function (operation) {
              if (operation.attributes && "font-family" in operation.attributes) {
                this.$store.dispatch("preview/setEditorFonts")
              }
            }.bind(this)
          )
        }.bind(this)
      )
      // this.setEditorContent(false)
      // clear formatting on paste
      this.editorInstance.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
        let ops = []
        delta.ops.forEach((op) => {
          if (op.insert && typeof op.insert === "string") {
            ops.push({
              insert: op.insert
            })
          }
        })
        delta.ops = ops
        return delta
      })

      if (this.color) {
        const quill = this.editorInstance
        const editor = this.$refs.editorNode

        let picker = document.getElementById(this.pickerId)
        if (!picker) {
          picker = document.createElement("input")
          picker.id = this.pickerId
          picker.type = "color"
          picker.style.width = "0px"
          picker.style.height = "0px"
          picker.style.visibility = "hidden"
          editor.parentElement.querySelector(".ql-toolbar .ql-color").parentElement.appendChild(picker)
          picker.addEventListener(
            "input",
            function () {
              quill.format("color", picker.value)
            },
            false
          )
        }

        picker = document.getElementById(this.pickerId)
        const toolbar = this.editorInstance.getModule("toolbar")
        toolbar.addHandler("color", function () {
          picker.click()
        })
      }
    },

    setEditorContent(emit = true) {
      if (this.editorInstance.getText().trim()) {
        this.editorContent = this.editorInstance.root.innerHTML.trim()
      } else {
        this.editorContent = ""
      }
      if (emit) {
        this.$emit("input", this.editorContent)
      }
      if (this.selector) {
        this.getIframeDocuments.forEach((doc) => (doc.querySelector(this.selector).innerHTML = this.editorContent))
      }
    }
  }
}
</script>

<style lang="scss">
@import "../../../../node_modules/quill/dist/quill.snow.css";

.simple-editor {
  @apply border-gray-200 border rounded-lg;
}

.ql-container.ql-snow {
  @apply border-none;
}

.simple-editor .ql-toolbar.ql-snow {
  @apply border-l-0 border-r-0 border-t-0 border-b border-gray-200 border-solid;
}

.ql-font-family {
  width: 7.5rem;

  .ql-picker-label::before,
  .ql-picker-options .ql-picker-item::before {
    content: "Use Parent";
  }

  .ql-picker-label[data-value="Nunito"]::before,
  .ql-picker-options .ql-picker-item[data-value="Nunito"]::before {
    content: "Nunito";
  }

  .ql-picker-label[data-value="Roboto"]::before,
  .ql-picker-options .ql-picker-item[data-value="Roboto"]::before {
    content: "Roboto";
  }

  .ql-picker-label[data-value="Poppins"]::before,
  .ql-picker-options .ql-picker-item[data-value="Poppins"]::before {
    content: "Poppins";
  }

  .ql-picker-label[data-value="Raleway"]::before,
  .ql-picker-options .ql-picker-item[data-value="Raleway"]::before {
    content: "Raleway";
  }

  .ql-picker-label[data-value="Open-Sans"]::before,
  .ql-picker-options .ql-picker-item[data-value="Open-Sans"]::before {
    content: "Open Sans";
  }

  .ql-picker-label[data-value="Lato"]::before,
  .ql-picker-options .ql-picker-item[data-value="Lato"]::before {
    content: "Lato";
  }

  .ql-picker-label[data-value="Montserrat"]::before,
  .ql-picker-options .ql-picker-item[data-value="Montserrat"]::before {
    content: "Montserrat";
  }

  .ql-picker-label[data-value="Source-Sans Pro"]::before,
  .ql-picker-options .ql-picker-item[data-value="Source-Sans-Pro"]::before {
    content: "Source Sans Pro";
  }

  .ql-picker-label[data-value="Noto-Sans"]::before,
  .ql-picker-options .ql-picker-item[data-value="Noto-Sans"]::before {
    content: "Noto Sans";
  }

  .ql-picker-label[data-value="PT-Sans"]::before,
  .ql-picker-options .ql-picker-item[data-value="PT-Sans"]::before {
    content: "PT Sans";
  }

  .ql-picker-label[data-value="Ubuntu"]::before,
  .ql-picker-options .ql-picker-item[data-value="Ubuntu"]::before {
    content: "Ubuntu";
  }

  .ql-picker-label[data-value="Fira-Sans"]::before,
  .ql-picker-options .ql-picker-item[data-value="Fira-Sans"]::before {
    content: "Fira Sans";
  }

  .ql-picker-label[data-value="Titillium-Web"]::before,
  .ql-picker-options .ql-picker-item[data-value="Titillium-Web"]::before {
    content: "Titillium Web";
  }

  .ql-picker-label[data-value="Oxygen"]::before,
  .ql-picker-options .ql-picker-item[data-value="Oxygen"]::before {
    content: "Oxygen";
  }
}

.ql-font-weight {
  width: 6.5rem;

  .ql-picker-label::before,
  .ql-picker-options .ql-picker-item::before {
    content: "Regular";
  }

  .ql-picker-label[data-value="300"]::before,
  .ql-picker-options .ql-picker-item[data-value="300"]::before {
    content: "Tiny";
  }

  .ql-picker-label[data-value="500"]::before,
  .ql-picker-options .ql-picker-item[data-value="500"]::before {
    content: "Medium";
  }

  .ql-picker-label[data-value="600"]::before,
  .ql-picker-options .ql-picker-item[data-value="600"]::before {
    content: "Semi-Bold";
  }

  .ql-picker-label[data-value="700"]::before,
  .ql-picker-options .ql-picker-item[data-value="700"]::before {
    content: "Bold";
  }

  .ql-picker-label[data-value="800"]::before,
  .ql-picker-options .ql-picker-item[data-value="800"]::before {
    content: "Extra-Bold";
  }

  .ql-picker-label[data-value="900"]::before,
  .ql-picker-options .ql-picker-item[data-value="900"]::before {
    content: "Black";
  }
}

.ql-line-height {
  width: 7rem;

  .ql-picker-options {
    max-height: 9rem;
    overflow-y: scroll;
  }

  .ql-picker-label::before,
  .ql-picker-options .ql-picker-item::before {
    content: "Line Height";
  }

  .ql-picker-label[data-value="0.80"]::before,
  .ql-picker-options .ql-picker-item[data-value="0.80"]::before {
    content: "0.80";
  }

  .ql-picker-label[data-value="0.85"]::before,
  .ql-picker-options .ql-picker-item[data-value="0.85"]::before {
    content: "0.85";
  }

  .ql-picker-label[data-value="0.90"]::before,
  .ql-picker-options .ql-picker-item[data-value="0.90"]::before {
    content: "0.90";
  }

  .ql-picker-label[data-value="0.95"]::before,
  .ql-picker-options .ql-picker-item[data-value="0.95"]::before {
    content: "0.95";
  }

  .ql-picker-label[data-value="1.00"]::before,
  .ql-picker-options .ql-picker-item[data-value="1.00"]::before {
    content: "1.00";
  }

  .ql-picker-label[data-value="1.05"]::before,
  .ql-picker-options .ql-picker-item[data-value="1.05"]::before {
    content: "1.05";
  }

  .ql-picker-label[data-value="1.10"]::before,
  .ql-picker-options .ql-picker-item[data-value="1.10"]::before {
    content: "1.10";
  }

  .ql-picker-label[data-value="1.15"]::before,
  .ql-picker-options .ql-picker-item[data-value="1.15"]::before {
    content: "1.15";
  }

  .ql-picker-label[data-value="1.20"]::before,
  .ql-picker-options .ql-picker-item[data-value="1.20"]::before {
    content: "1.20";
  }

  .ql-picker-label[data-value="1.25"]::before,
  .ql-picker-options .ql-picker-item[data-value="1.25"]::before {
    content: "1.25";
  }

  .ql-picker-label[data-value="1.30"]::before,
  .ql-picker-options .ql-picker-item[data-value="1.30"]::before {
    content: "1.30";
  }

  .ql-picker-label[data-value="1.35"]::before,
  .ql-picker-options .ql-picker-item[data-value="1.35"]::before {
    content: "1.35";
  }

  .ql-picker-label[data-value="1.40"]::before,
  .ql-picker-options .ql-picker-item[data-value="1.40"]::before {
    content: "1.40";
  }

  .ql-picker-label[data-value="1.45"]::before,
  .ql-picker-options .ql-picker-item[data-value="1.45"]::before {
    content: "1.45";
  }

  .ql-picker-label[data-value="1.50"]::before,
  .ql-picker-options .ql-picker-item[data-value="1.50"]::before {
    content: "1.50";
  }

  .ql-picker-label[data-value="1.60"]::before,
  .ql-picker-options .ql-picker-item[data-value="1.60"]::before {
    content: "1.60";
  }

  .ql-picker-label[data-value="1.70"]::before,
  .ql-picker-options .ql-picker-item[data-value="1.70"]::before {
    content: "1.70";
  }

  .ql-picker-label[data-value="1.80"]::before,
  .ql-picker-options .ql-picker-item[data-value="1.80"]::before {
    content: "1.80";
  }

  .ql-picker-label[data-value="1.90"]::before,
  .ql-picker-options .ql-picker-item[data-value="1.90"]::before {
    content: "1.90";
  }

  .ql-picker-label[data-value="2.00"]::before,
  .ql-picker-options .ql-picker-item[data-value="2.00"]::before {
    content: "2.00";
  }

  .ql-picker-label[data-value="2.50"]::before,
  .ql-picker-options .ql-picker-item[data-value="2.50"]::before {
    content: "2.50";
  }

  .ql-picker-label[data-value="3.00"]::before,
  .ql-picker-options .ql-picker-item[data-value="3.00"]::before {
    content: "3.00";
  }

  .ql-picker-label[data-value="3.50"]::before,
  .ql-picker-options .ql-picker-item[data-value="3.50"]::before {
    content: "3.50";
  }

  .ql-picker-label[data-value="4.00"]::before,
  .ql-picker-options .ql-picker-item[data-value="4.00"]::before {
    content: "4.00";
  }

  .ql-picker-label[data-value="4.50"]::before,
  .ql-picker-options .ql-picker-item[data-value="4.50"]::before {
    content: "4.50";
  }

  .ql-picker-label[data-value="5.00"]::before,
  .ql-picker-options .ql-picker-item[data-value="5.00"]::before {
    content: "5.00";
  }
}
</style>
