<template>
  <input-with-unit v-if="opacity" v-model="opacity" :alternative-units="['%']" size="normal" @updated="set" />
</template>
<script>
import { mapGetters } from "vuex"
import imageToolMixin from "@/mixins/images/image-tool-mixin"
import InputWithUnit from "@/components/common/InputWithUnit"

export default {
  name: "ImageWidth",
  components: { InputWithUnit },
  data() {
    return {
      opacity: null
    }
  },
  mixins: [imageToolMixin],
  computed: {
    ...mapGetters("preview", ["getCurrentStyleVariables"])
  },
  methods: {
    fillDataFromImage() {
      this.opacity = {
        value: this.img.style["opacity"] * 100,
        unit: "%"
      }
    },
    set() {
      this.img.style["opacity"] = this.opacity.value / 100
    }
  },
  mounted() {
    const styleVars = this.getCurrentStyleVariables.style[this.env]
    if (!this.img.style["opacity"]) this.img.style["opacity"] = styleVars["--image-opacity"]
    this.fillDataFromImage()
  }
}
</script>
