import { mapState } from "vuex"

const imageToolMixin = {
  props: ["activeImageIndex", "imagesClass"],
  data() {
    return {
      img: null
    }
  },
  computed: {
    getPageSelector() {
      return this.$store.getters["preview/getPageSelector"]
    },
    ...mapState("preview", { env: "appearanceDevice" })
  },
  mounted() {
    const iframe = this.$store.state.preview.iframes[this.env]
    const images = iframe.contentWindow.document.querySelectorAll(`${this.getPageSelector} .${this.imagesClass} img`)
    this.img = images[this.activeImageIndex]
  }
}
export default imageToolMixin
