<template>
  <div>
    <settings-element>
      <template #title> Gift Box Count: {{ giftBoxCount }}</template>
      <template #body>
        <input id="gift-count" v-model="giftBoxCount" class="col" max="7" min="2" type="range" @change="changeGiftBoxCount" />
      </template>
    </settings-element>
    <settings-element v-for="(_, key) in couponData" :key="key">
      <template #title> Coupon #{{ key + 1 }}</template>
      <template #body>
        <label class="flex items-center mb-2">
          <span class="w-24 mr-4">Message:</span>
          <text-input v-model="couponData[key].message" type="text" @input="changeCouponData" />
        </label>
        <label class="flex items-center mb-2">
          <span class="w-24 mr-4">Coupon:</span>
          <text-input v-model="couponData[key].coupon" type="text" @input="changeCouponData" />
        </label>
        <label class="flex items-center mb-2">
          <span class="w-24 mr-4">Weight:</span>
          <text-input v-model="couponData[key].weight" type="text" @input="changeCouponData" />
        </label>
      </template>
    </settings-element>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import { giftPickerHtml } from "@/lib/marketing-widgets/gift-picker-html"
import SettingsElement from "@/components/campaign-builder/widget-settings/SettingsElement"
import TextInput from "@/components/common/TextInput"

export default {
  name: "Coupons",
  components: { TextInput, SettingsElement },
  data() {
    return {
      giftBoxCount: 0,
      couponData: []
    }
  },
  computed: mapGetters("preview", ["getIframeDocuments", "getPageSelector"]),
  methods: {
    ...mapActions("preview", ["reloadIframes"]),
    changeGiftBoxCount(event) {
      const newCount = event.target.value
      this.getIframeDocuments.forEach((doc) => {
        doc.querySelectorAll(`${this.getPageSelector} .ma-gift-picker .ma-gift-box`).forEach((el) => el.remove())
        const giftBoxHtml = new giftPickerHtml().getGiftboxHtml().repeat(newCount)
        doc.querySelector(`${this.getPageSelector} .ma-gift-picker`).innerHTML += giftBoxHtml
      })
      this.reloadIframes()
    },
    changeCouponData() {
      this.getIframeDocuments.forEach((doc) => {
        doc.querySelector(`${this.getPageSelector} .ma-gift-picker`).dataset.gift = JSON.stringify(this.couponData)
      })
    }
  },
  mounted() {
    this.giftBoxCount = this.getIframeDocuments[0].querySelectorAll(`${this.getPageSelector} .ma-gift-picker .ma-gift-box`).length
    this.couponData = JSON.parse(this.getIframeDocuments[0].querySelector(`${this.getPageSelector} .ma-gift-picker`).dataset.gift)
  }
}
</script>
