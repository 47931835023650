import randomId from "@/lib/utils/random-id"

class FormElementHelper {
  constructor(documents, formSelector) {
    this.documents = documents
    this.formSelector = formSelector
    this.nameStore = null
  }

  addField(type) {
    this.nameStore = null
    this.fieldId = randomId()
    this.documents.forEach((doc) => {
      const div = doc.createElement("div")
      div.classList.add("field")
      let elementHTML = ""
      if (type === "input-text") elementHTML = this.getInputText()
      else if (type === "input-number") elementHTML = this.getInputNumber()
      else if (type === "input-date") elementHTML = this.getInputDate()
      else if (type === "input-tel") elementHTML = this.getInputTel()
      else if (type === "input-url") elementHTML = this.getInputUrl()
      else if (type === "text-area") elementHTML = this.getTextArea()
      else if (type === "checkbox") {
        div.classList.add("checkbox")
        elementHTML = this.getCheckBox()
      } else if (type === "email") elementHTML = this.getEmail()
      else if (type === "select") elementHTML = this.getSelect()
      else if (type === "radios") elementHTML = this.getRadios()
      else if (type === "rating") {
        div.classList.add("rating")
        elementHTML = this.getRating()
      }
      div.innerHTML = elementHTML
      doc.querySelector(`${this.formSelector}`).appendChild(div)
    })
  }

  getInputText() {
    return `<input class='user-input' id='${this.fieldId}' autocomplete='off' required='true' name='${this.getName(
      "single-line-text"
    )}' type='text' placeholder='Placeholder...'>`
  }

  getInputNumber() {
    return `<input class='user-input' id='${this.fieldId}' autocomplete='off' required='true' name='${this.getName(
      "number"
    )}' type='number' placeholder='Placeholder...'>`
  }

  getInputDate() {
    return `<input class='user-input osm-input-date' data-date-format='Y-m-d' id='${
      this.fieldId
    }' autocomplete='off' required='true' name='${this.getName("date")}' type='text' placeholder='Pick a Date'>`
  }

  getInputTel() {
    return `<input class='user-input osm-input-tel' id='${this.fieldId}' autocomplete='off' required='true' name='${this.getName(
      "phone-number"
    )}' type='tel' placeholder=''>`
  }

  getInputUrl() {
    return `<input required='true' class='user-input' autocomplete='off' id='${this.fieldId}' name='${this.getName(
      "url"
    )}' type='url' placeholder='Placeholder...' value=''>`
  }

  getTextArea() {
    return `<textarea rows='4' style='resize:none' autocomplete='off' required='true' class='user-input' id='${
      this.fieldId
    }' name='${this.getName("multi-line-text")}' placeholder='Placeholder...'></textarea>`
  }

  getCheckBox() {
    const name = this.getName("checkbox")
    return `<label for='${this.fieldId}' style='margin: 0' class='not-parent-label'>
                <input id='${this.fieldId}' required='true' name='${name}' value='checked' class='user-input' type='checkbox'>
                <span>${name}</span>
                </label>`
  }

  getEmail() {
    return `<input required='true' class='user-input' autocomplete='off' id='${this.fieldId}' name='${this.getName(
      "email"
    )}' type='email' placeholder='Placeholder...' value=''>`
  }

  getSelect() {
    return `<select required='true' id='${this.fieldId}' name='${this.getName("dropdown-select")}' class='user-input'></select>`
  }

  getRating() {
    const name = this.getName("rating")
    let html = ""
    for (let i = 5; i >= 1; i--) {
      html += `<input required='true' type='radio' name='${name}' id='${name}${i}' value='${i}'>
          <label for='${name}${i}' class='not-parent-label'>
          <svg viewBox='0 0 32 32'>
          <polygon points='16 23.369 6.333 30.5 10.2 19.09 1.5 12.434 12.133 12.434 16 1.5 19.867 12.434 30.5 12.434 21.8 19.09 25.667 30.5'></polygon>
          </svg>
          </label>`
    }
    return `<div id='${this.fieldId}' class='user-input user-input-rating'>${html}</div>`
  }

  getRadios() {
    const name = this.getName("radio 1")
    let html = ""
    for (let i = 1; i <= 3; i++) {
      const randId = randomId()
      html += `<label for='${randId}' class='not-parent-label'>
                <input id='${randId}' required='true' name='${name}' value='Option ${i}' class='user-input' type='radio'>
                <span>Option ${i}</span>
              </label>`
    }
    return `<div id='${randomId()}' class='user-input radios'>${html}</div>`
  }

  getName(name) {
    if (this.nameStore !== null) {
      return this.nameStore
    }
    const forms = this.documents[0].querySelectorAll("form")
    if (!forms) return name

    const namesInUse = []
    forms.forEach((form) => {
      form.querySelectorAll("[name]").forEach((elementWithName) => {
        namesInUse.push(elementWithName.name)
      })
    })

    let duplicateId = 0
    let baseName = name
    while (namesInUse.find((nameInUse) => nameInUse === name) !== undefined) {
      duplicateId += 1
      name = baseName + " - " + duplicateId
    }
    this.nameStore = name
    return name
  }
}

export default FormElementHelper
