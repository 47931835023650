<template>
  <div>
    <settings-title>{{ getTitle }}</settings-title>
    <SettingsDeviceSelector />
    <div :key="appearanceDevice">
      <template v-if="setAreaTopic === 'overlay'">
        <SettingsVisibility variable="--overlay-display" />
        <SettingsColor title="Overlay Color" variable="--overlay-color" />
        <SettingsBlur variable="--overlay-backdrop-blur" />
      </template>
    </div>
  </div>
</template>

<script>
import SettingsDeviceSelector from "@/components/campaign-builder/SettingsDeviceSelector"
import appearanceSettingsMixin from "@/mixins/appearance-settings-mixin"
import SettingsTitle from "@/components/campaign-builder/widget-settings/SettingsTitle"
import { mapMutations } from "vuex"

export default {
  name: "WhatsappAppearance",
  components: {
    SettingsTitle,
    SettingsDeviceSelector
  },
  mixins: [appearanceSettingsMixin],
  data() {
    return {
      selectorMap: {
        layout: ".layout"
      }
    }
  },
  computed: {
    getTitle() {
      return {
        overlay: "Overlay Appearance"
      }[this.setAreaTopic]
    }
  },
  methods: mapMutations("preview", ["setAppearanceDevice"]),
  created() {
    this.setAppearanceDevice("mobile")
  }
}
</script>
