<template>
  <SettingsWrapper>
    <template #content-buttons>
      <border-button @click="activateSetArea('content-coupons')">Gift Boxes & Coupons</border-button>
      <border-button @click="activateSetArea('content-title')">Title</border-button>
      <border-button @click="activateSetArea('content-subtitle')">Subtitle</border-button>
      <border-button @click="activateSetArea('content-description')">Description</border-button>
      <border-button @click="activateSetArea('content-images')"> Images</border-button>
      <border-button @click="activateSetArea('content-form')">Form</border-button>
      <border-button @click="activateSetArea('content-buttons')">Buttons</border-button>
      <border-button @click="activateSetArea('content-notes')">Notes</border-button>
      <border-button @click="activateSetArea('content-custom-actions')">Custom Actions</border-button>
    </template>
    <template #appearance-buttons>
      <border-button @click="activateSetArea('overlay')">Overlay</border-button>
      <border-button @click="activateSetArea('layout')">Layout</border-button>
      <div class="ml-4">
        <border-button @click="activateSetArea('close-button')"> Close Button</border-button>
        <border-button :disabled="isImageEmpty" class="bordered-button" @click="activateSetArea('image')"> Images </border-button>
        <border-button class="bordered-button" @click="activateSetArea('body')">Body</border-button>
      </div>
      <div class="ml-8">
        <border-button :disabled="isTitleEmpty" @click="activateSetArea('title')">Title</border-button>
        <border-button :disabled="isSubTitleEmpty" @click="activateSetArea('subtitle')">Subtitle</border-button>
        <border-button :disabled="isDescriptionEmpty" @click="activateSetArea('description')"> Description </border-button>
        <border-button class="bordered-button" @click="activateSetArea('gift-boxes')">Gift Boxes</border-button>
        <border-button :disabled="isFormEmpty" @click="activateSetArea('form')">Form</border-button>
      </div>
      <div class="ml-12">
        <border-button :disabled="isFormEmpty" @click="activateSetArea('form-fields')">Form Fields</border-button>
        <border-button :disabled="isLabelsEmpty" @click="activateSetArea('form-labels')">Form Labels</border-button>
      </div>
      <div class="ml-8">
        <border-button :disabled="isPrimaryButtonEmpty" @click="activateSetArea('primary-button')"> Primary Button </border-button>
        <border-button :disabled="isSecondaryButtonEmpty" @click="activateSetArea('secondary-button')"> Secondary Button </border-button>
        <border-button :disabled="isNotesEmpty" @click="activateSetArea('notes')">Notes</border-button>
      </div>
    </template>
    <template #appearance>
      <GiftPickerAppearance />
    </template>
    <template #content>
      <GiftPickerContents />
    </template>
  </SettingsWrapper>
</template>

<script>
import GiftPickerAppearance from "@/components/campaign-builder/widget-settings/gift-picker/GiftPickerAppearance"
import GiftPickerContents from "@/components/campaign-builder/widget-settings/gift-picker/GiftPickerContents"
import BorderButton from "@/components/BorderButton"
import SettingsWrapper from "@/components/campaign-builder/widget-settings/SettingsWrapper"
import settingsMixin from "@/mixins/settings-mixin"
import { mapGetters } from "vuex"

export default {
  name: "GiftPickerSettings",
  components: {
    SettingsWrapper,
    BorderButton,
    GiftPickerContents,
    GiftPickerAppearance
  },
  mixins: [settingsMixin],
  data() {
    return {
      isNotesEmpty: false,
      isDescriptionEmpty: false,
      isTitleEmpty: false,
      isSubTitleEmpty: false,
      isPrimaryButtonEmpty: false,
      isSecondaryButtonEmpty: false,
      isImageEmpty: false,
      isFormEmpty: false,
      isLabelsEmpty: false
    }
  },
  computed: {
    ...mapGetters("preview", ["getIframeDocuments", "getPageSelector"]),
    activeSettings() {
      return this.$store.state.preview.activeSettings
    }
  },
  watch: {
    activeSettings() {
      this.setDisabledButtons()
    }
  },
  methods: {
    setDisabledButtons() {
      let docs = this.getIframeDocuments
      if (docs) {
        const doc = docs[0]
        this.isFormEmpty = doc.querySelectorAll(`${this.getPageSelector} .form .field`).length < 1
        this.isLabelsEmpty = doc.querySelectorAll(`${this.getPageSelector} .form .field label`).length < 1
        this.isPrimaryButtonEmpty = doc.querySelectorAll(`${this.getPageSelector} .buttons .primary`).length < 1
        this.isSecondaryButtonEmpty = doc.querySelectorAll(`${this.getPageSelector} .buttons .secondary`).length < 1
        this.isImageEmpty = doc.querySelectorAll(`${this.getPageSelector} .images img`).length < 1
        this.isNotesEmpty = doc.querySelector(`${this.getPageSelector} .notes`).innerText.length < 1
        this.isDescriptionEmpty = doc.querySelector(`${this.getPageSelector} .description`).innerText.length < 1
        this.isTitleEmpty = doc.querySelector(`${this.getPageSelector} .title`).innerText.length < 1
        this.isSubTitleEmpty = doc.querySelector(`${this.getPageSelector} .subtitle`).innerText.length < 1
      }
    }
  },
  mounted() {
    this.setDisabledButtons()
  }
}
</script>
