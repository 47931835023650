<template>
  <div class="text-gray-600 mb-4">
    <button
      :class="[appearanceDevice === 'mobile' ? 'bg-blue-600 text-white' : '']"
      class="rounded-md px-8 py-2 font-medium mr-4 bg-gray-200"
      @click="setAppearanceDevice('mobile')"
    >
      Mobile
    </button>
    <button
      :class="[appearanceDevice === 'desktop' ? 'bg-blue-600 text-white' : '']"
      class="rounded-md px-8 py-2 font-medium mr-4 bg-gray-200"
      @click="setAppearanceDevice('desktop')"
    >
      Desktop
    </button>
  </div>
</template>
<script>
import { mapMutations, mapState } from "vuex"

export default {
  name: "SettingsDeviceSelector",
  computed: mapState("preview", ["appearanceDevice"]),
  methods: mapMutations("preview", ["setAppearanceDevice"])
}
</script>
