<template>
  <div>
    <Checkbox v-model="isRequired" @input="changeIsRequired">Required</Checkbox>
  </div>
</template>

<script>
import Checkbox from "@/components/common/Checkbox"

export default {
  name: "InputTel",
  props: ["element", "elementKey", "eachFormData"],
  components: { Checkbox },
  data() {
    return {
      isRequired: ""
    }
  },
  methods: {
    changeIsRequired() {
      this.eachFormData((f) => {
        f[this.elementKey]["field"].required = this.isRequired
      })
    }
  },
  mounted() {
    this.isRequired = this.element.required
  }
}
</script>
