<template>
  <dropdown-select @change="changeMobileDevice">
    <option selected value="414-736">Iphone 6/7/8 Plus</option>
    <option value="320-568">Iphone 5/SE</option>
    <option value="375-812">Iphone X</option>
    <option value="360-740">Samsung S9</option>
  </dropdown-select>
</template>

<script>
import DropdownSelect from "@/components/common/DropdownSelect"

export default {
  name: "MobileResolutionSelector",
  components: { DropdownSelect },
  methods: {
    changeMobileDevice(e) {
      const value = e.target.value.split("-")
      const device = this.$store.state.preview.previewDevices.mobile
      device.width = value[0]
      device.height = value[1]
    }
  }
}
</script>
