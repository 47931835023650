<template>
  <input-with-unit v-if="height" v-model="height" :alternative-units="['px', 'vh', '%']" size="small" @updated="set" />
</template>

<script>
import { mapGetters } from "vuex"
import parseUnit from "@/lib/utils/parse-unit"
import imageToolMixin from "@/mixins/images/image-tool-mixin"
import InputWithUnit from "@/components/common/InputWithUnit"

export default {
  name: "ImageHeight",
  components: { InputWithUnit },
  mixins: [imageToolMixin],
  data() {
    return {
      height: null
    }
  },
  computed: {
    ...mapGetters("preview", ["getCurrentStyleVariables"])
  },
  methods: {
    fillDataFromImage() {
      const { unit, value } = parseUnit(this.img.style.height)
      this.height = { unit, value }
    },
    set() {
      this.img.style["height"] = this.height.value + this.height.unit
    }
  },
  mounted() {
    const styleVars = this.getCurrentStyleVariables.style[this.env]
    if (!this.img.style.height) this.img.style.height = styleVars["--image-height"]
    this.fillDataFromImage()
  }
}
</script>
