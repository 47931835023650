<template>
  <button
    :disabled="disabled"
    class="border-button block h-10 w-full my-2 text-base text-left font-medium py-0 px-4 rounded-lg transition-colors shadow-md text-white bg-blue-600 hover:bg-blue-700"
    @click="$emit('click', $event)"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: "BorderButton",
  props: {
    disabled: {
      default: false
    }
  }
}
</script>
<style lang="scss" scoped>
.border-button:disabled {
  @apply bg-red-300 cursor-not-allowed;
}
</style>
