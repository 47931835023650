import { mapGetters, mapState } from "vuex"

const appearanceSettingsMixin = {
  data() {
    return {
      selectorMap: {}
    }
  },
  computed: {
    ...mapGetters("preview", ["getPageSelector"]),
    ...mapState("preview", ["appearanceDevice"]),
    setAreaTopic() {
      return this.$store.state.preview.setAreaTopic
    }
  },
  watch: {
    appearanceDevice() {
      this.outline()
    },
    setAreaTopic() {
      this.outline()
    }
  },
  methods: {
    outline() {
      if (this.setAreaTopic && this.setAreaTopic in this.selectorMap) {
        this.$store.commit("preview/addOutline", {
          device: this.appearanceDevice,
          selector: `${this.getPageSelector} ${this.selectorMap[this.setAreaTopic]}`
        })
      }
    }
  },
  beforeDestroy() {
    this.$store.commit("preview/clearOutlines")
  },
  mounted() {
    this.outline()
  }
}

export default appearanceSettingsMixin
