<template>
  <div class="edit-page bg-gray-100 relative h-screen max-h-screen">
    <CampaignBuilderHeader :generateHtml="getHTML" />
    <CampaignBuilderLoader v-if="loading" :class="[!loading ? 'opacity-0' : '']" class="absolute w-screen left-0 h-80" />
    <div :class="[loading ? 'opacity-0' : '']" class="body flex">
      <div class="left flex-1 h-full flex flex-col overflow-hidden">
        <Preview />
      </div>
      <div
        :class="showEditor ? 'css-editor-open' : null"
        class="right bg-white border-l border-gray-200 transition-all duration-200 flex-grow-0 flex-shrink-0 relative overflow-x-hidden overflow-y-auto"
      >
        <div :key="getTypeOfCurrentPage + getCurrentPage" class="settings-top">
          <ModalSettings v-if="getTypeOfCurrentPage === 'modal'" />
          <FlatBarSettings v-if="getTypeOfCurrentPage === 'flat-bar'" />
          <WhatsappSettings v-if="getTypeOfCurrentPage === 'whatsapp'" />
          <WheelOfFortuneSettings v-if="getTypeOfCurrentPage === 'wheel-of-fortune'" />
          <GiftPickerSettings v-if="getTypeOfCurrentPage === 'gift-picker'" />
          <SatisMeterSettings v-if="getTypeOfCurrentPage === 'satis-meter'" />
          <ImageButtonSettings v-if="getTypeOfCurrentPage === 'image-button'" />
          <JustImageSettings v-if="getTypeOfCurrentPage === 'just-image'" />
          <div
            v-if="showEditor"
            class="css-editor-area absolute bg-white right-0 top-0 w-full overflow-x-hidden overflow-y-auto transition-all duration-300"
          >
            <CssEditor></CssEditor>
          </div>
        </div>
        <div class="h-12 flex border-t border-gray-200 items-center px-2 justify-between">
          <PageOperations />
          <button class="text-sm text-white bg-blue-500 text-white px-2 py-1 rounded-md" @click="showEditor = !showEditor">
            <span v-if="showEditor">Close Css Editor</span>
            <span v-else>Open Css Editor</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex"
import ModalSettings from "@/components/campaign-builder/widget-settings/modal/ModalSettings"
import Preview from "@/components/campaign-builder/Preview"
import parseWidgetHtml from "@/lib/utils/parse-widget-html"
import HtmlGenerator from "@/lib/marketing-widgets/html-generator"
import FlatBarSettings from "@/components/campaign-builder/widget-settings/flat-bar/FlatBarSettings"
import PageOperations from "@/components/campaign-builder/PageOperations"
import CssEditor from "@/components/campaign-builder/CssEditor"
import WhatsappSettings from "@/components/campaign-builder/widget-settings/whatsapp/WhatsappSettings"
import WheelOfFortuneSettings from "@/components/campaign-builder/widget-settings/wheel-of-fortune/WheelOfFortuneSettings"
import GiftPickerSettings from "@/components/campaign-builder/widget-settings/gift-picker/GiftPickerSettings"
import SatisMeterSettings from "@/components/campaign-builder/widget-settings/satis-meter/SatisMeterSettings"
import CampaignBuilderHeader from "@/components/campaign-builder/CampaignBuilderHeader"
import CampaignBuilderLoader from "@/components/campaign-builder/CampaignBuilderLoader"
import ImageButtonSettings from "@/components/campaign-builder/widget-settings/image-button/ImageButtonSettings"
import JustImageSettings from "@/components/campaign-builder/widget-settings/just-image/JustImageSettings"
import htmlExporter from "@/lib/utils/html-exporter"

export default {
  name: "EditCampaign",
  components: {
    JustImageSettings,
    ImageButtonSettings,
    CampaignBuilderLoader,
    CampaignBuilderHeader,
    SatisMeterSettings,
    GiftPickerSettings,
    WheelOfFortuneSettings,
    CssEditor,
    WhatsappSettings,
    PageOperations,
    FlatBarSettings,
    Preview,
    ModalSettings
  },
  computed: {
    ...mapGetters("preview", [
      "getIframeDocuments",
      "getIframes",
      "getPageSelector",
      "getCurrentPage",
      "getPreviewDevicesLoading",
      "getParentId"
    ]),
    getTypeOfCurrentPage() {
      return this.getIframeDocuments[0]?.querySelector(`${this.getPageSelector}`).dataset.maType
    }
  },
  watch: {
    getPreviewDevicesLoading(newVal) {
      if (!newVal) {
        this.loading = false
      }
    }
  },
  data() {
    return {
      showEditor: false,
      loading: true
    }
  },
  methods: {
    ...mapActions("preview", ["reloadIframes"]),
    ...mapMutations("preview", ["initStyleVariables", "setHtml", "setTotalPage", "setPreviewDevicesLoading", "setParentId", "setHtml"]),
    getHTML() {
      this.getIframeDocuments.forEach((doc) => doc.dispatchEvent(new Event("osmBeforeSave")))
      const cloneNode = this.getIframeDocuments[0].cloneNode(true)
      const html = htmlExporter.getHtml(this.getIframes, cloneNode)
      const formFields = htmlExporter.getFormFields(cloneNode)
      console.log({ formFields })
      const element = document.createElement("a")
      element.setAttribute("href", "data:text/plain;charset=utf-8, " + encodeURIComponent(html))
      element.setAttribute("download", "marketing.html")
      document.body.appendChild(element)
      element.click()
      document.body.removeChild(element)
      this.getIframeDocuments.forEach((doc) => doc.dispatchEvent(new Event("osmAfterSave")))
      return 1
    }
  },
  created() {
    this.loading = true
    if (this.$store.state.preview.initFromHtml) {
      const html = this.$store.state.preview.htmlConfig
      const { styleVariables, parentId, totalPage } = parseWidgetHtml(html)
      this.setParentId(parentId)
      this.setTotalPage(totalPage)
      this.initStyleVariables(styleVariables)
      this.setHtml(html)
    } else if (this.$store.state.preview.initFromZero) {
      const { parentId, styleVariables, html } = new HtmlGenerator(1, this.$store.state.preview.zeroConfig).get()
      this.setHtml(html)
      this.setParentId(parentId)
      this.initStyleVariables({
        1: styleVariables
      })
    } else {
      this.$router.push({ name: "CreateCampaign" })
    }
  }
}
</script>
<style lang="scss">
// @todo : move this css to related files!
$rightWidth: var(--right-width);
:root {
  --settings-top-height: 4rem;
  --campaign-builder-header-height: 4rem;
  --campaign-builder-body-height: calc(100vh - var(--campaign-builder-header-height));
}

.edit-page {
  .campaign-builder-loader {
    height: var(--campaign-builder-body-height);
  }

  .body {
    height: var(--campaign-builder-body-height);

    > .right {
      flex-basis: $rightWidth;
      height: var(--campaign-builder-body-height);

      .settings-top {
        height: calc(100% - 3rem);
      }

      .settings-wrapper .settings-set-area {
        right: $rightWidth;
        height: calc(100% - 3rem);

        &.active {
          right: 0;
        }

        .close {
          height: var(--settings-top-height);
        }
      }

      .css-editor-area {
        right: $rightWidth;
        height: calc(100% - 3rem);
      }

      &.css-editor-open {
        flex-basis: var(--css-editor-width);

        .css-editor-area {
          right: 0;
        }
      }
    }

    > .left .preview-area .preview {
      height: calc(100% - 3rem);

      &::-webkit-scrollbar {
        width: 0.5rem;
        height: 0.5rem;
      }

      &::-webkit-scrollbar-track {
        background: #f5f5f5;
      }

      &::-webkit-scrollbar-thumb {
        background: #c9c9c9;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #b3b3b3;
      }
    }
  }
}
</style>
