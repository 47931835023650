<template>
  <div>
    <SettingsBackgroundColor variable="--body-background" />
    <settings-element>
      <template #title>Position</template>
      <template #body>
        <select v-if="device === 'desktop'" v-model="desktopPositionClass" @change="changePositionClass">
          <option v-for="positionClass in Object.keys(desktopAvailablePositionClassList)" :key="positionClass" :value="positionClass">
            {{ desktopAvailablePositionClassList[positionClass] }}
          </option>
        </select>
        <select v-if="device === 'mobile'" v-model="mobilePositionClass" @change="changePositionClass">
          <option v-for="positionClass in Object.keys(mobileAvailablePositionClassList)" :key="positionClass" :value="positionClass">
            {{ mobileAvailablePositionClassList[positionClass] }}
          </option>
        </select>
      </template>
    </settings-element>
    <SettingsFontFamily variable="--layout-font-family" />
    <SettingsPadding variable="--body-padding" />
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import SettingsElement from "@/components/campaign-builder/widget-settings/SettingsElement"

export default {
  name: "Layout",
  components: { SettingsElement },
  computed: {
    ...mapGetters("preview", ["getPageSelector", "getIframeDocuments", "getIframes"])
  },
  data() {
    return {
      desktopAvailablePositionClassList: {
        "d-top-overlap-sticky": "Top Overlap Sticky",
        "d-top-push-down-sticky": "Top Push Down Sticky",
        "d-top-overlap": "Top Overlap",
        "d-top-push-down": "Top Push Down"
      },
      mobileAvailablePositionClassList: {
        "m-top-overlap-sticky": "Top Overlap Sticky",
        "m-top-push-down-sticky": "Top Push Down Sticky",
        "m-top-overlap": "Top Overlap",
        "m-top-push-down": "Top Push Down"
      },
      desktopPositionElement: null,
      desktopPositionClass: "",
      mobilePositionElement: null,
      mobilePositionClass: ""
    }
  },
  methods: {
    changePositionClass() {
      this.desktopPositionElement.className = `position ${this.desktopPositionClass} ${this.mobilePositionClass}`
      this.mobilePositionElement.className = `position ${this.desktopPositionClass} ${this.mobilePositionClass}`
    }
  },
  mounted() {
    this.desktopPositionElement = this.getIframes["desktop"].contentWindow.document.querySelector(`${this.getPageSelector} [data-position]`)
    this.mobilePositionElement = this.getIframes["mobile"].contentWindow.document.querySelector(`${this.getPageSelector} [data-position]`)
    Object.keys(this.desktopAvailablePositionClassList).forEach((cl) => {
      if (this.desktopPositionElement.classList.contains(cl)) {
        this.desktopPositionClass = cl
      }
    })
    Object.keys(this.mobileAvailablePositionClassList).forEach((cl) => {
      if (this.mobilePositionElement.classList.contains(cl)) {
        this.mobilePositionClass = cl
      }
    })
  }
}
</script>
