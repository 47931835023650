<template>
  <settings-content-appearance>
    <template slot="content">
      <settings-element>
        <template slot="title">Currently Editing</template>
        <template slot="body">
          <dropdown-select v-model.number="currentTab">
            <option v-for="i in tabCount" :key="i" :selected="currentTab === i - 1" :value="i - 1">Question {{ i }}</option>
          </dropdown-select>
          <button class="ml-4 px-3 py-1 bg-blue-600 rounded-md text-white" @click="addTab">Add</button>
          <button v-if="tabCount > 1" class="ml-1 px-3 py-1 bg-red-600 rounded-md text-white" @click="removeTab(currentTab)">Remove</button>
        </template>
      </settings-element>
      <settings-element>
        <template slot="title">Question</template>
        <template slot="body">
          <MiniEditor
            :key="`${getPageSelector}+${currentTab}+1`"
            :align="false"
            :line-height="false"
            :link="false"
            :selector="`${getPageSelector} [data-tab='${currentTab}'] .question`"
          />
        </template>
      </settings-element>
      <settings-element>
        <template slot="title">Answer Type</template>
        <template slot="body">
          <label class="block mb-4">
            <span class="inline-block w-28">Select Type</span>
            <dropdown-select v-model="type" @change="changeType">
              <option value="number">Number</option>
              <option value="text">Text</option>
              <option value="free-text">Free Text</option>
            </dropdown-select>
          </label>
          <div v-if="type === 'number'">
            <label class="block mb-4">
              <span class="inline-block w-28">Last Number</span>
              <dropdown-select v-model="numberLastNumber" @change="changeNumber">
                <option v-for="i in 8" :key="i" :value="i + 2">{{ i + 2 }}</option>
              </dropdown-select>
            </label>
            <Checkbox v-model="numberStartFromZero" @change="changeNumber">Start From Zero</Checkbox>
            <div class="text-gray-900 font-medium mb-2 mt-6">Bottom Left Message</div>
            <MiniEditor
              :key="`${getPageSelector}+${currentTab}+2`"
              :align="false"
              :line-height="false"
              :link="false"
              :selector="`${getPageSelector} [data-tab='${currentTab}'] .bottom-messages .left`"
            />
            <div class="text-gray-900 font-medium mb-2 mt-6">Bottom Right Message</div>
            <MiniEditor
              :key="`${getPageSelector}+${currentTab}+3`"
              :align="false"
              :line-height="false"
              :link="false"
              :selector="`${getPageSelector} [data-tab='${currentTab}'] .bottom-messages .right`"
            />
          </div>
          <div v-if="type === 'text'">
            <div v-for="(_, k) in textAnswers" :key="k" class="px-2 py-2 mb-2 rounded-md border border-gray-200">
              <div class="mb-2">
                <label class="flex">
                  <span class="inline-block w-28">Answer:</span>
                  <text-input v-model="textAnswers[k].text" padding="small" @input="changeAnswerData(k)" />
                </label>
              </div>
              <div>
                <label class="flex">
                  <span class="inline-block w-28">Value:</span>
                  <text-input v-model="textAnswers[k].value" padding="small" @input="changeAnswerData(k)" />
                </label>
              </div>
              <div class="text-right">
                <button class="px-2 py-1 rounded-md bg-red-500 text-white mt-2" @click="deleteAnswer(k)">Delete Answer</button>
              </div>
            </div>
            <div class="text-right mt-2">
              <button class="bg-indigo-600 text-white px-4 py-2 rounded-md" @click="addAnswer">Add Answer</button>
            </div>
          </div>
        </template>
      </settings-element>
    </template>
    <template slot="appearance">
      <settings-element>
        <template slot="title"> Position</template>
        <template slot="body">
          <label class="flex items-center mb-4">
            <span class="inline-block w-28">Desktop</span>
            <dropdown-select v-model="desktopPosition" @change="changePosition">
              <option value="top-left">Top Left</option>
              <option value="top-center">Top Center</option>
              <option value="top-right">Top Right</option>
              <option value="center-left">Center Left</option>
              <option value="center-center">Center Center</option>
              <option value="center-right">Center Right</option>
              <option value="bottom-left">Bottom Left</option>
              <option value="bottom-center">Bottom Center</option>
              <option value="bottom-right">Bottom Right</option>
            </dropdown-select>
          </label>
          <label class="flex items-center">
            <span class="inline-block w-28">Mobile</span>
            <dropdown-select v-model="mobilePosition" @change="changePosition">
              <option value="top">Top</option>
              <option value="center">Center</option>
              <option value="bottom">Bottom</option>
            </dropdown-select>
          </label>
        </template>
      </settings-element>
      <SettingsColor title="Layout Background Color" variable="--layout-background-color" />
      <settings-element>
        <template slot="title">Button Background Color</template>
        <template slot="body">
          <label class="flex items-center mb-4">
            <span class="w-28">Normal</span>
            <Color variable="--button-background-color" />
          </label>
          <label class="flex items-center mb-4">
            <span class="w-28">Hover</span>
            <Color variable="--button-hover-background-color" />
          </label>
        </template>
      </settings-element>
      <settings-element>
        <template slot="title">Button Color</template>
        <template slot="body">
          <label class="flex items-center mb-4">
            <span class="w-28">Normal</span>
            <Color variable="--button-color" />
          </label>
          <label class="flex items-center mb-4">
            <span class="w-28">Hover</span>
            <Color variable="--button-hover-color" />
          </label>
        </template>
      </settings-element>
      <SettingsColor title="Close Button Foreground" variable="--close-button-color" />
      <SettingsBackgroundColor title="Close Button Background" variable="--close-button-background" />
      <SettingsVisibility
        :can-be-delayed="true"
        selector=".close-button"
        title="Close Button Visibility"
        variable="--close-button-display"
      />
    </template>
  </settings-content-appearance>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex"
import Color from "@/components/campaign-builder/appearance-changers/Color"
import typeText from "!raw-loader!./raw-html/type-text.html"
import typeNumber from "!raw-loader!./raw-html/type-number.html"
import tab from "!raw-loader!./raw-html/tab.html"

import typeFreeText from "!raw-loader!./raw-html/type-free-text.html"
import MiniEditor from "@/components/campaign-builder/content-changers/MiniEditor"
import SettingsElement from "@/components/campaign-builder/widget-settings/SettingsElement"
import DropdownSelect from "@/components/common/DropdownSelect"
import Checkbox from "@/components/common/Checkbox"
import TextInput from "@/components/common/TextInput"
import randomId from "@/lib/utils/random-id"
import SettingsContentAppearance from "@/components/campaign-builder/SettingsContentAppearance"

export default {
  name: "SatisMeterSettings",
  components: {
    SettingsContentAppearance,
    TextInput,
    Checkbox,
    DropdownSelect,
    SettingsElement,
    MiniEditor,
    Color
  },
  data() {
    return {
      currentTab: 0,
      tabCount: 0,
      eventCache: [],
      desktopPosition: "",
      mobilePosition: "",
      numberLastNumber: 5,
      numberStartFromZero: false,
      textAnswers: [],
      type: ""
    }
  },
  computed: mapGetters("preview", ["getIframeDocuments", "getPageSelector"]),
  watch: {
    currentTab() {
      this.initTab()
    }
  },
  methods: {
    ...mapActions("preview", ["reloadIframes"]),
    ...mapMutations("preview", ["setAppearanceDevice"]),
    addTab() {
      this.getIframeDocuments.forEach((doc) => {
        let html = ""
        const tabsParent = doc.querySelector(`${this.getPageSelector} .satismeter-tabs`)
        tabsParent.querySelectorAll(`.satismeter-tab`).forEach((tabInFrame, index) => {
          html += tabInFrame.outerHTML
          if (index === this.currentTab) html += tab
          tabInFrame.remove()
        })
        tabsParent.innerHTML += html
      })
      this.currentTab += 1
      this.initTab()
    },
    removeTab(tabNumber) {
      this.getIframeDocuments.forEach((doc) => {
        const tab = doc.querySelectorAll(`${this.getPageSelector} .satismeter-tabs .satismeter-tab`)[tabNumber]
        tab.remove()
      })
      const isLast = this.tabCount - 1 === this.currentTab
      if (isLast) {
        this.currentTab -= 1
        this.initTab()
      }
      this.initTab()
    },
    changePosition() {
      this.getIframeDocuments.forEach((doc) => {
        const positionElement = doc.querySelector(`${this.getPageSelector} .position`)
        positionElement.dataset.desktop = this.desktopPosition
        positionElement.dataset.mobile = this.mobilePosition
      })
    },
    changeType() {
      this.getIframeDocuments.forEach((doc) => {
        const tab = doc.querySelectorAll(`${this.getPageSelector} .satismeter-tabs .satismeter-tab`)[this.currentTab]
        const answerElement = tab.querySelector(".answer")
        if (this.type === "text") answerElement.innerHTML = typeText
        else if (this.type === "free-text") answerElement.innerHTML = typeFreeText
        else if (this.type === "number") answerElement.innerHTML = typeNumber
      })
      this.fillDataFromWidget()
      this.initTab()
    },
    changeNumber() {
      let html = ""
      let max = 1
      if (this.numberStartFromZero) max = 0
      for (let i = this.numberLastNumber; i >= max; i--) {
        html += `<button class="answer-button" data-value="${i}">${i}</button>`
      }
      this.getIframeDocuments.forEach((doc) => {
        const tab = doc.querySelectorAll(`${this.getPageSelector} .satismeter-tabs .satismeter-tab`)[this.currentTab]
        tab.querySelector(`.answer-options`).innerHTML = html
      })
      this.initTab()
    },
    addAnswer() {
      this.textAnswers.push({
        text: "New Answer",
        value: "New Value"
      })
      this.updateTextAnswer()
    },
    deleteAnswer(index) {
      this.textAnswers = this.textAnswers.filter((value, key) => key !== index)
      this.updateTextAnswer()
    },
    changeAnswerData(key) {
      this.getIframeDocuments.forEach((doc) => {
        const tab = doc.querySelectorAll(`${this.getPageSelector} .satismeter-tabs .satismeter-tab`)[this.currentTab]
        const button = tab.querySelectorAll(`.answer-options .answer-button`)[key]
        button.dataset.value = this.textAnswers[key].value
        button.innerText = this.textAnswers[key].text
      })
    },
    updateTextAnswer() {
      let html = ""
      this.textAnswers.forEach((answer) => {
        html += `<button class="answer-button" data-value="${answer.value}">${answer.text}</button>`
      })
      this.getIframeDocuments.forEach((doc) => {
        const tab = doc.querySelectorAll(`${this.getPageSelector} .satismeter-tabs .satismeter-tab`)[this.currentTab]
        tab.querySelector(`.answer-options`).innerHTML = html
      })
      this.fillDataFromWidget()
      this.initTab()
    },
    fillDataFromWidget() {
      const tab = this.getIframeDocuments[0].querySelectorAll(`${this.getPageSelector} .satismeter-tabs .satismeter-tab`)[this.currentTab]
      const buttons = tab.querySelectorAll(".answer-options .answer-button")
      if (this.type === "number") {
        this.numberStartFromZero = buttons[buttons.length - 1].dataset.value === "0"
        this.numberLastNumber = buttons[0].dataset.value
      } else if (this.type === "text") {
        this.textAnswers = []
        buttons.forEach((button) => {
          this.textAnswers.push({
            value: button.dataset.value,
            text: button.innerText
          })
        })
      }
    },
    initTab(event) {
      this.getIframeDocuments.forEach((doc) => {
        let k = 0
        const tabs = doc.querySelectorAll(`${this.getPageSelector} .satismeter-tabs .satismeter-tab`)
        tabs.forEach((tab) => {
          tab.dataset.tab = `${k}`
          k++
        })

        if (event) {
          const { eventId, tabIndex } = event.detail
          doc.dispatchEvent(
            new CustomEvent("satisMeterDashboardCommunication", {
              detail: { eventId, tabIndex, source: "dashboard" }
            })
          )
        } else {
          const eventId = randomId()
          doc.dispatchEvent(
            new CustomEvent("satisMeterDashboardCommunication", {
              detail: {
                eventId,
                tabIndex: this.currentTab,
                source: "dashboard"
              }
            })
          )
        }
      })

      const tabs = this.getIframeDocuments[0].querySelectorAll(`${this.getPageSelector} .satismeter-tabs .satismeter-tab`)
      this.tabCount = tabs.length
      this.type = tabs[this.currentTab].querySelector(".answer-options").dataset.type
      this.fillDataFromWidget()
    }
  },
  created() {
    this.setAppearanceDevice("all")
    const position = this.getIframeDocuments[0].querySelector(`${this.getPageSelector} .position`)
    this.desktopPosition = position.dataset.desktop
    this.mobilePosition = position.dataset.mobile
    this.currentTab = 0
    this.initTab()

    this.getIframeDocuments.forEach((doc) => {
      doc.addEventListener("satisMeterDashboardCommunication", (event) => {
        const { eventId, tabIndex, source } = event.detail
        if (!this.eventCache.includes(eventId) && source !== "dashboard") {
          this.eventCache.push(eventId)
          this.currentTab = tabIndex
          this.initTab(event)
        }
      })
    })
  }
}
</script>

<style lang="scss" scoped>
.settings-area {
  width: 100%;
  color: black;
  background: #fff;
  max-height: 100%;
  overflow-y: auto;
  z-index: 99;

  aside {
    padding: 1.25rem;
    width: 100%;
  }
}

.settings-area {
  padding: 0 1rem;
  padding-bottom: 1rem;

  .flex {
    div {
      flex-basis: 45%;

      select {
        width: 100%;
      }
    }
  }

  .background-color {
    width: 100%;
    margin-top: 1rem;
  }

  h2 {
    width: 100%;
    padding: 0rem 0rem 0.5rem;
    font-size: 1.25rem;
    line-height: 1;
    border-bottom: 1px solid #ddd;
    margin: 2rem 0 1rem 0;
  }

  h3 {
    margin-top: 0.25rem;
  }
}

.number-edit {
  div {
    margin-top: 16px;
  }

  .last-number {
    select {
      padding: 6px 12px;
    }
  }

  .number-start {
    label {
      display: flex;
      align-items: center;

      input {
        margin-left: 8px;
      }
    }
  }
}

.text-edit {
  .answer-group {
    background: #ddd;
    padding: 8px;
    border-radius: 4px;
    margin: 8px 0;

    .answer-text,
    .answer-value {
      label {
        margin: 8px 0;
        display: flex;
        align-items: center;

        input {
          padding: 4px 8px;
          margin-left: 20px;
        }
      }
    }
  }

  .text-delete-button,
  .text-add-button {
    padding: 4px 6px;
    border-radius: 4px;
    background: var(--primary);
    color: white;
  }
}
</style>
