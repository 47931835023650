<template>
  <div>
    <base-modal :show="showAddPageModal" title="What Type Of Page You Want To Add?" @close="showAddPageModal = false">
      <template #body>
        <campaign-type-selector @next="addNewPage" />
      </template>
    </base-modal>
    <div class="flex items-center">
      <dropdown-select v-model="selectedPage">
        <option v-for="page in getTotalPage" :key="page" :selected="selectedPage === page" :value="page">Page {{ page }}</option>
      </dropdown-select>
      <button class="text-sm ml-2 px-2 py-1 rounded-md text-white bg-blue-500" @click="showAddPageModal = true">Add</button>
      <button v-if="getTotalPage > 1" class="text-sm ml-2 px-2 py-1 rounded-md text-white bg-red-500" @click="removePage">Remove</button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import DropdownSelect from "@/components/common/DropdownSelect"
import BaseModal from "@/components/common/BaseModal"
import CampaignTypeSelector from "@/components/campaign-type-select/CampaignTypeSelector"

export default {
  name: "PageOperations",
  components: { CampaignTypeSelector, BaseModal, DropdownSelect },
  data() {
    return {
      showAddPageModal: false
    }
  },
  computed: {
    ...mapGetters("preview", ["getTotalPage", "getCurrentPage"]),
    selectedPage: {
      get() {
        return this.getCurrentPage
      },
      set(value) {
        this.$store.commit("preview/clearOutlines")
        this.changePage(value)
      }
    }
  },
  methods: {
    ...mapActions("preview", ["changePage"]),
    addNewPage(data) {
      this.$store.dispatch("preview/addNewPage", data)
      this.showAddPageModal = false
    },
    removePage() {
      this.$store.dispatch("preview/removePage")
    }
  }
}
</script>
