<template>
  <div>
    <div v-for="(message, key) in messages" :key="key" class="mb-6">
      <div class="flex items-center justify-between mb-2">
        <div class="font-medium">Message {{ key + 1 }}</div>
        <button class="remove" @click="removeMessage(key)">
          <icon-x class="h-4 w-4 text-gray-800" />
        </button>
      </div>
      <MiniEditor :align="false" :line-height="false" :value="message.text" @input="(msg) => setMessageText(key, msg)" />
    </div>
    <button class="px-1 py-1 rounded-full block mx-auto bg-blue-600" @click="addNewMessage">
      <icon-plus class="h-6 w-6 text-white" />
    </button>
    <div class="mt-6">
      <div class="font-medium mb-2">Not Understand Message</div>
      <MiniEditor
        v-if="notUnderstandMessage !== null"
        :align="false"
        :line-height="false"
        :value="notUnderstandMessage"
        @input="(msg) => setNotUnderstandMessage(msg)"
      />
    </div>
  </div>
</template>

<script>
import MiniEditor from "@/components/campaign-builder/content-changers/MiniEditor"
import { mapGetters } from "vuex"
import IconX from "@/icons/icon-x.svg"
import IconPlus from "@/icons/icon-plus.svg"

export default {
  name: "Messages",
  components: { MiniEditor, IconPlus, IconX },
  data() {
    return {
      messages: [],
      notUnderstandMessage: null
    }
  },
  methods: {
    initialize() {
      const messages = this.getIframeDocuments[0].querySelector(`${this.getPageSelector} .whatsapp-popup`).dataset.initialMessages
      this.messages = JSON.parse(messages)
      this.notUnderstandMessage = this.getIframeDocuments[0].querySelector(
        `${this.getPageSelector} .whatsapp-popup`
      ).dataset.notUnderstandMessage
    },
    setMessageText(key, text) {
      this.messages[key].text = text
      this.setMessageDataToHtml()
    },
    setNotUnderstandMessage(text) {
      this.notUnderstandMessage = text
      this.setMessageDataToHtml()
    },
    setMessageDataToHtml() {
      if (this.notUnderstandMessage === "") {
        this.notUnderstandMessage = " "
      }
      if (
        this.messages.length <=
        this.getIframeDocuments[0].querySelectorAll(`${this.getPageSelector} .whatsapp-popup .last-messages .message`).length
      ) {
        this.getIframeDocuments.forEach((doc) => {
          doc.querySelector(`${this.getPageSelector} .whatsapp-popup`).setAttribute("data-initial-messages", JSON.stringify(this.messages))
          doc
            .querySelector(`${this.getPageSelector} .whatsapp-popup`)
            .setAttribute("data-not-understand-message", this.notUnderstandMessage)
          doc.querySelectorAll(`${this.getPageSelector} .whatsapp-popup .last-messages .message`).forEach((e) => e.remove())
          this.messages.forEach((message) => {
            const p = document.createElement("p")
            p.classList.add("message", "from-first")
            p.innerHTML =
              `<div class="message-text">${message.text}</div><span class="message-time">` +
              new Date().getHours() +
              ":" +
              new Date().getHours() +
              "</span>"
            doc
              .querySelector(`${this.getPageSelector} .whatsapp-popup .last-messages .action-buttons`)
              .insertAdjacentElement("beforebegin", p)
          })
        })
      }
    },
    removeMessage(key) {
      this.messages.splice(key, 1)
      this.setMessageDataToHtml()
    },
    addNewMessage() {
      this.messages.push({ text: "Write message here", from: "first" })
      this.getIframeDocuments.forEach((doc) => {
        const p = doc.createElement("p")
        p.classList.add("message", "from-first")
        p.innerHTML =
          "<div class='message-text'><p>Write your message here...</p></div><span class='message-time'>" +
          new Date().getHours() +
          ":" +
          new Date().getHours() +
          "</span>"
        doc.querySelector(`${this.getPageSelector} .whatsapp-popup .last-messages .action-buttons`).insertAdjacentElement("beforebegin", p)
      })
      this.setMessageDataToHtml()
      this.initialize()
    }
  },
  computed: {
    ...mapGetters("preview", ["getIframeDocuments", "getPageSelector"])
  },
  mounted() {
    this.initialize()
  }
}
</script>
