<template>
  <div>
    <settings-title>{{ getTitle }}</settings-title>
    <Coupons v-if="setAreaTopic === 'content-coupons'" />
    <MiniEditor v-if="setAreaTopic === 'content-title'" :selector="`${getPageSelector} .title`" />
    <MiniEditor v-if="setAreaTopic === 'content-subtitle'" :selector="`${getPageSelector} .subtitle`" />
    <MiniEditor v-if="setAreaTopic === 'content-description'" :selector="`${getPageSelector} .description`" />
    <Images v-if="setAreaTopic === 'content-images'" images-class="images" />
    <ContentFormFields v-if="setAreaTopic === 'content-form'" />
    <DoubleButtons
      v-if="setAreaTopic === 'content-buttons'"
      :button-area-selector="`${getPageSelector} .form-and-buttons .buttons`"
      primary-button-class="primary"
      secondary-button-class="secondary"
    />
    <MiniEditor v-if="setAreaTopic === 'content-notes'" :selector="`${getPageSelector} .notes`" />
    <template v-if="setAreaTopic === 'content-custom-actions'">
      <Checkbox v-model="customActionsOverlayClose">Close Widget When Click To Overlay</Checkbox>
      <Checkbox v-model="customActionsEscClose">Close Widget When Pressed "ESC"</Checkbox>
    </template>
  </div>
</template>
<script>
import MiniEditor from "@/components/campaign-builder/content-changers/MiniEditor"
import { mapGetters } from "vuex"
import Images from "@/components/campaign-builder/content-changers/Images"
import Coupons from "@/components/campaign-builder/widget-settings/gift-picker/Content/Coupons"
import ContentFormFields from "@/components/campaign-builder/content-changers/general-form-fields/ContentFormFields"
import SettingsTitle from "@/components/campaign-builder/widget-settings/SettingsTitle"
import DoubleButtons from "@/components/campaign-builder/content-changers/DoubleButtons"
import Checkbox from "@/components/common/Checkbox"

export default {
  name: "GiftPickerContents",
  components: {
    Checkbox,
    DoubleButtons,
    SettingsTitle,
    ContentFormFields,
    Coupons,
    Images,
    MiniEditor
  },
  data() {
    return {
      customActionsOverlayClose: false,
      customActionsEscClose: false
    }
  },
  computed: {
    ...mapGetters("preview", ["getIframeDocuments", "getPageSelector"]),
    setAreaTopic() {
      return this.$store.state.preview.setAreaTopic
    },
    getTitle() {
      return {
        "content-coupons": "Gift Boxes & Coupons",
        "content-title": "Title",
        "content-subtitle": "Subtitle",
        "content-description": "Description",
        "content-images": "Images",
        "content-form": "Form",
        "content-buttons": "Buttons",
        "content-notes": "Notes",
        "content-custom-actions": "Custom Actions"
      }[this.setAreaTopic]
    }
  },
  watch: {
    overlayClose(newV) {
      this.getIframeDocuments.forEach((doc) => {
        if (newV) doc.querySelector(`${this.getPageSelector} .overlay`).setAttribute("data-close", "true")
        else doc.querySelector(`${this.getPageSelector} .overlay`).removeAttribute("data-close")
      })
    },
    escClose(newV) {
      this.getIframeDocuments.forEach((doc) => {
        if (newV) doc.querySelector(`${this.getPageSelector}`).setAttribute("data-esc-close", "true")
        else doc.querySelector(`${this.getPageSelector}`).removeAttribute("data-esc-close")
      })
    }
  },
  created() {
    if (this.getIframeDocuments[0].querySelector(`${this.getPageSelector} .overlay`).getAttribute("data-close")) {
      this.customActionsOverlayClose = true
    }
    if (this.getIframeDocuments[0].querySelector(`${this.getPageSelector}`).getAttribute("data-esc-close")) {
      this.customActionsEscClose = true
    }
  }
}
</script>
