<template>
  <div
    :class="[show ? '' : 'hidden']"
    class="modal h-screen w-full fixed left-0 top-0 flex justify-center items-center bg-black bg-opacity-50 z-50"
    @click.self="$emit('close')"
  >
    <!-- modal -->
    <div class="bg-white rounded-lg shadow-2xl w-10/12 md:w-4/5 lg:w-3/5">
      <!-- modal header -->
      <div class="border-b px-4 py-4 flex justify-between items-center">
        <h3 class="font-semibold text-2xl">
          {{ title }}
        </h3>
        <button @click="$emit('close')">
          <icon-x class="w-6 h-6" />
        </button>
      </div>
      <div class="p-4">
        <slot name="body" />
      </div>
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
import IconX from "@/icons/icon-x.svg"

export default {
  name: "BaseModal",
  components: {
    IconX
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped></style>
