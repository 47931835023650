<template>
  <div class="flex items-center my-2">
    <span class="w-16">Name</span>
    <div class="flex-1">
      <text-input v-model="name" padding="small" @input="changeName" />
    </div>
  </div>
</template>

<script>
import TextInput from "@/components/common/TextInput"

export default {
  name: "Name",
  components: { TextInput },
  props: ["eachFormData", "elementKey", "element"],
  data() {
    return {
      name: ""
    }
  },
  methods: {
    changeName() {
      this.eachFormData((f) => {
        f[this.elementKey]["field"].name = this.name
      })
    }
  },
  mounted() {
    this.name = this.element.name
  }
}
</script>
