<template>
  <div>
    <div class="mb-4 flex">
      <div class="w-28">
        <dropdown-select v-model="horizontalAttribute" @change="setImagePositionAfterChange">
          <option value="right">Right</option>
          <option value="left">Left</option>
        </dropdown-select>
      </div>
      <input-with-unit
        v-if="horizontal"
        v-model="horizontal"
        :alternative-units="['px', 'vw', '%']"
        size="normal"
        @updated="setImagePositionAfterChange"
      />
    </div>
    <div class="flex">
      <div class="w-28">
        <dropdown-select v-model="verticalAttribute" @change="setImagePositionAfterChange">
          <option value="top">Top</option>
          <option value="bottom">Bottom</option>
        </dropdown-select>
      </div>
      <input-with-unit
        v-if="vertical"
        v-model="vertical"
        :alternative-units="['px', 'vh', '%']"
        size="normal"
        @updated="setImagePositionAfterChange"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import parseUnit from "@/lib/utils/parse-unit"
import imageToolMixin from "@/mixins/images/image-tool-mixin"
import InputWithUnit from "@/components/common/InputWithUnit"
import DropdownSelect from "@/components/common/DropdownSelect"

export default {
  name: "ImagePosition",
  components: { DropdownSelect, InputWithUnit },
  data() {
    return {
      horizontalAttribute: "",
      verticalAttribute: "",
      horizontal: null,
      vertical: null
    }
  },
  mixins: [imageToolMixin],
  computed: {
    ...mapGetters("preview", ["getCurrentStyleVariables"])
  },
  methods: {
    fillDataFromImage() {
      this.horizontalAttribute = this.img.style.left !== "unset" ? "left" : "right"
      this.verticalAttribute = this.img.style.top !== "unset" ? "top" : "bottom"
      this.horizontal = parseUnit(this.img.style[this.horizontalAttribute])
      this.vertical = parseUnit(this.img.style[this.verticalAttribute])
    },
    setImagePositionAfterChange() {
      if (this.horizontalAttribute === "right") this.img.style["left"] = "unset"
      else this.img.style["right"] = "unset"

      this.img.style[this.horizontalAttribute] = this.horizontal.value + this.horizontal.unit

      if (this.verticalAttribute === "top") this.img.style["bottom"] = "unset"
      else this.img.style["top"] = "unset"

      this.img.style[this.verticalAttribute] = this.vertical.value + this.vertical.unit
    }
  },
  mounted() {
    const styleVars = this.getCurrentStyleVariables.style[this.env]

    const left = styleVars["--image-left"]
    const right = styleVars["--image-right"]
    const top = styleVars["--image-top"]
    const bottom = styleVars["--image-bottom"]

    if (!this.img.style.top && !this.img.style.bottom) {
      if (top <= bottom) {
        this.img.style.top = top
        this.img.style["bottom"] = "unset"
      } else {
        this.img.style.bottom = bottom
        this.img.style["top"] = "unset"
      }
    }

    if (!this.img.style.left && !this.img.style.right) {
      if (left <= right) {
        this.img.style.left = left
        this.img.style["right"] = "unset"
      } else {
        this.img.style.right = right
        this.img.style["left"] = "unset"
      }
    }
    this.fillDataFromImage()
  }
}
</script>
