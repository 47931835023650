<template>
  <div class="settings-wrapper h-full flex items-center border-b border-gray-200 w-full">
    <div :class="[isSetAreaActive === false ? 'active' : '']" class="text-gray-800 w-full h-full overflow-y-auto">
      <div class="px-4 py-2 rounded-lg settings-tab-selector-wrapper">
        <div class="flex items-center justify-between h-full bg-gray-100 rounded-lg border overflow-hidden border-gray-200">
          <button
            :class="activeSettings === 'content' ? 'font-medium  bg-blue-600 text-white' : ''"
            class="uppercase text-sm tracking-wider font-bold text-center w-full h-full rounded-none"
            @click="activeSettings = 'content'"
          >
            Content
          </button>
          <button
            :class="activeSettings === 'appearance' ? 'font-medium  bg-blue-600 text-white' : ''"
            class="uppercase text-sm tracking-wider font-bold text-center w-full h-full rounded-none"
            @click="activeSettings = 'appearance'"
          >
            Appearance
          </button>
        </div>
      </div>
      <aside class="px-4 mt-8">
        <div v-if="activeSettings === 'content'" class="content">
          <slot name="content-buttons" />
        </div>
        <div v-if="activeSettings === 'appearance'" class="appearance">
          <slot name="appearance-buttons" />
        </div>
      </aside>
    </div>
    <div
      :class="[isSetAreaActive === true ? 'active' : '']"
      class="settings-set-area bg-white p-5 absolute top-0 w-full overflow-x-hidden overflow-y-auto transition-all duration-300"
    >
      <button class="close absolute left-1 top-9 text-gray-800 transform -translate-y-1/2 leading-none" @click="closeSetArea">
        <icon-chevron-left class="h-8 w-8" />
      </button>
      <div v-if="isSetAreaActive && activeSettings === 'content'" :key="appearanceKey">
        <slot name="content"></slot>
      </div>
      <div v-if="isSetAreaActive && activeSettings === 'appearance'" :key="contentsKey">
        <slot name="appearance"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import randomId from "@/lib/utils/random-id"
import IconChevronLeft from "@/icons/icon-chevron-left.svg"

export default {
  name: "SettingsWrapper",
  components: { IconChevronLeft },
  data() {
    return {
      contentsKey: "c",
      appearanceKey: "a"
    }
  },
  computed: {
    isSetAreaActive: {
      get() {
        return this.$store.state.preview.isSetAreaActive
      },
      set(value) {
        this.$store.state.preview.isSetAreaActive = value
      }
    },
    activeSettings: {
      get() {
        return this.$store.state.preview.activeSettings
      },
      set(value) {
        this.$store.state.preview.activeSettings = value
      }
    }
  },
  methods: {
    closeSetArea() {
      this.isSetAreaActive = false
      this.setAreaTopic = ""
    }
  },
  created() {
    this.$store.watch(
      () => this.$store.state.preview.currentPage,
      () => {
        this.setAreaTopic = ""
        this.isSetAreaActive = false
        this.contentsKey = "c" + randomId()
        this.appearanceKey = "c" + randomId()
      }
    )
  }
}
</script>
<style lang="scss">
.settings-tab-selector-wrapper {
  height: var(--settings-top-height);
}
</style>
