<template>
  <SettingsWrapper>
    <template #content-buttons>
      <border-button @click="activateSetArea('content-sender')">Sender</border-button>
      <border-button @click="activateSetArea('content-messages')">Messages</border-button>
      <border-button @click="activateSetArea('content-buttons')">Buttons</border-button>
    </template>
    <template #appearance-buttons>
      <border-button @click="activateSetArea('overlay')">Overlay</border-button>
    </template>
    <template #appearance>
      <WhatsappAppearance />
    </template>
    <template #content>
      <WhatsappContents />
    </template>
  </SettingsWrapper>
</template>

<script>
import BorderButton from "@/components/BorderButton"
import SettingsWrapper from "@/components/campaign-builder/widget-settings/SettingsWrapper"
import settingsMixin from "@/mixins/settings-mixin"
import WhatsappContents from "@/components/campaign-builder/widget-settings/whatsapp/WhatsappContents"
import WhatsappAppearance from "@/components/campaign-builder/widget-settings/whatsapp/WhatsappAppearance"

export default {
  name: "WhatsappSettings",
  components: {
    WhatsappAppearance,
    WhatsappContents,
    SettingsWrapper,
    BorderButton
  },
  mixins: [settingsMixin]
}
</script>
