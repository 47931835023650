<template>
  <div class="campaign-builder-loader flex items-center justify-center">
    <div class="w-auto bg-white shadow-lg px-14 pt-12 pb-8 rounded-full">
      <div class="loading-balls flex items-center justify-between space-x-3">
        <div class="w-6 h-6 bg-gray-800 rounded-full animate-bounce"></div>
        <div class="w-6 h-6 bg-gray-800 rounded-full animate-bounce"></div>
        <div class="w-6 h-6 bg-gray-800 rounded-full animate-bounce"></div>
        <div class="w-6 h-6 bg-gray-800 rounded-full animate-bounce"></div>
        <div class="w-6 h-6 bg-gray-800 rounded-full animate-bounce"></div>
      </div>
      <div class="font-medium text-center text-lg text-gray-800 mt-6">Let's build something super fucking awesome!</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CampaignBuilderLoader"
}
</script>

<style lang="scss" scoped>
.campaign-builder-loader {
  .loading-balls {
    div {
      &:nth-of-type(1) {
        animation-delay: 0.05s;
      }

      &:nth-of-type(2) {
        animation-delay: 0.1s;
      }

      &:nth-of-type(3) {
        animation-delay: 0.15s;
      }

      &:nth-of-type(4) {
        animation-delay: 0.2s;
      }

      &:nth-of-type(5) {
        animation-delay: 0.25s;
      }
    }
  }
}
</style>
