<template>
  <SettingsWrapper>
    <template #content-buttons>
      <border-button @click="activateSetArea('content-message')">Message</border-button>
      <border-button @click="activateSetArea('content-button')">Button</border-button>
      <border-button @click="activateSetArea('content-images')">Images</border-button>
    </template>
    <template #content>
      <Contents />
    </template>
    <template #appearance-buttons>
      <border-button @click="activateSetArea('layout')">Layout</border-button>
      <div class="ml-4">
        <border-button @click="activateSetArea('close-button')">Close Button</border-button>
        <border-button @click="activateSetArea('message')">Message</border-button>
        <border-button @click="activateSetArea('images')">Images</border-button>
        <border-button :disabled="buttonActive" @click="activateSetArea('bar-button')">Button</border-button>
      </div>
    </template>
    <template #appearance>
      <Appearance />
    </template>
  </SettingsWrapper>
</template>

<script>
import SettingsWrapper from "@/components/campaign-builder/widget-settings/SettingsWrapper"
import BorderButton from "@/components/BorderButton"
import settingsMixin from "@/mixins/settings-mixin"
import Contents from "@/components/campaign-builder/widget-settings/flat-bar/Contents"
import Appearance from "@/components/campaign-builder/widget-settings/flat-bar/Appearance"
import { mapGetters } from "vuex"

export default {
  name: "FlatBarSettings",
  mixins: [settingsMixin],
  components: { Appearance, Contents, SettingsWrapper, BorderButton },
  data() {
    return {
      buttonActive: false
    }
  },
  computed: {
    ...mapGetters("preview", ["getIframeDocuments", "getPageSelector"]),
    activeSettings() {
      return this.$store.state.preview.activeSettings
    }
  },
  watch: {
    activeSettings() {
      this.setDisabledButtons()
    }
  },
  methods: {
    setDisabledButtons() {
      this.buttonActive = !this.getIframeDocuments[0].querySelector(`${this.getPageSelector} .form-and-buttons button.primary`)
    }
  },
  mounted() {
    this.setDisabledButtons()
  }
}
</script>
