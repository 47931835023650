<template>
  <dropdown-select @change="changeDesktopDevice">
    <option selected value="1024-768">Mini Desktop</option>
    <option value="1366-768">Middle Desktop</option>
    <option value="1920-1080">Big Desktop</option>
  </dropdown-select>
</template>

<script>
import DropdownSelect from "@/components/common/DropdownSelect"

export default {
  name: "DesktopResolutionSelector",
  components: { DropdownSelect },
  methods: {
    changeDesktopDevice(e) {
      const value = e.target.value.split("-")
      const device = this.$store.state.preview.previewDevices.desktop
      device.width = value[0]
      device.height = value[1]
    }
  }
}
</script>
