<template>
  <div>
    <input-with-label v-model="placeholder" @input="changePlaceHolder">Placeholder</input-with-label>
    <checkbox v-model="isRequired" @input="changeIsRequired">Required</checkbox>
  </div>
</template>

<script>
import InputWithLabel from "@/components/common/InputWithLabel"
import Checkbox from "@/components/common/Checkbox"

export default {
  name: "InputText",
  components: { Checkbox, InputWithLabel },
  props: ["element", "elementKey", "eachFormData"],
  data() {
    return {
      isRequired: "",
      placeholder: ""
    }
  },
  methods: {
    changePlaceHolder() {
      this.eachFormData((f) => {
        f[this.elementKey]["field"].placeholder = this.placeholder
      })
    },
    changeIsRequired() {
      this.eachFormData((f) => {
        f[this.elementKey]["field"].required = this.isRequired
      })
    }
  },
  mounted() {
    this.isRequired = this.element.required
    this.placeholder = this.element.placeholder
  }
}
</script>
