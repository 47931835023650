<template>
  <SettingsContentAppearance>
    <template slot="content">
      <settings-element>
        <template slot="title">Choose Your Image</template>
        <template slot="body">
          <ContentImages :allow-only-change="true" images-class="images" />
        </template>
      </settings-element>
      <settings-element>
        <template slot="title">Image Click Actions</template>
        <template slot="body">
          <SingleButton
            :button-area-selector="`${getPageSelector} .images`"
            :inner-editable="false"
            :show-title="false"
            button-class="just-image"
          />
        </template>
      </settings-element>
      <settings-element>
        <template slot="title">Overlay Click</template>
        <template slot="body">
          <Checkbox v-model="overlayClose">Close Widget When Click To Overlay</Checkbox>
        </template>
      </settings-element>
    </template>
    <template slot="appearance">
      <settings-device-selector />
      <div :key="appearanceDevice">
        <div class="space-x-2 mt-8">
          <button
            :class="{ 'bg-indigo-600 text-white': appearanceActiveTab === 'image' }"
            class="rounded-md px-4 py-2 font-medium bg-gray-200"
            @click="appearanceActiveTab = 'image'"
          >
            Image
          </button>
          <button
            :class="{ 'bg-indigo-600 text-white': appearanceActiveTab === 'close-button' }"
            class="rounded-md px-4 py-2 font-medium bg-gray-200"
            @click="appearanceActiveTab = 'close-button'"
          >
            Close Button
          </button>
          <button
            :class="{ 'bg-indigo-600 text-white': appearanceActiveTab === 'overlay' }"
            class="rounded-md px-4 py-2 font-medium bg-gray-200"
            @click="appearanceActiveTab = 'overlay'"
          >
            Overlay
          </button>
        </div>
        <div v-if="appearanceActiveTab === 'image'" :key="appearanceActiveTab">
          <settings-element>
            <template slot="body">
              <AppearanceImages :show-position="false" :show-title="false" images-class="images" />
            </template>
          </settings-element>
          <SettingsBorderRadius variable="--image-border-radius" />
        </div>
        <div v-if="appearanceActiveTab === 'close-button'" :key="appearanceActiveTab">
          <SettingsColor title="Foreground Color" variable="--close-button-color" />
          <SettingsBackgroundColor variable="--close-button-background" />
          <SettingsSize :variables="['--close-button-width', '--close-button-height']" title="Icon Size" />
          <SettingsVisibility :can-be-delayed="true" selector=".close-button" variable="--close-button-display" />
          <SettingsPadding variable="--close-button-padding" />
          <SettingsBorderRadius variable="--close-button-border-radius" />
          <SettingsElement>
            <template #title>Position</template>
            <template #body>
              <TwoDirectionPosition
                :variable="{
                  top: '--close-button-top',
                  left: '--close-button-left',
                  right: '--close-button-right',
                  bottom: '--close-button-bottom'
                }"
              />
            </template>
          </SettingsElement>
        </div>
        <div v-if="appearanceActiveTab === 'overlay'" :key="appearanceActiveTab">
          <SettingsVisibility variable="--overlay-display" />
          <SettingsColor variable="--overlay-color" />
          <SettingsBlur variable="--overlay-backdrop-blur" />
        </div>
      </div>
    </template>
  </SettingsContentAppearance>
</template>

<script>
import SettingsElement from "@/components/campaign-builder/widget-settings/SettingsElement"
import ContentImages from "@/components/campaign-builder/content-changers/Images"
import AppearanceImages from "@/components/campaign-builder/appearance-changers/Images"
import SettingsContentAppearance from "@/components/campaign-builder/SettingsContentAppearance"
import SettingsDeviceSelector from "@/components/campaign-builder/SettingsDeviceSelector"
import SingleButton from "@/components/campaign-builder/content-changers/SingleButton"
import TwoDirectionPosition from "@/components/campaign-builder/appearance-changers/TwoDirectionPosition"
import Checkbox from "@/components/common/Checkbox"
import { mapGetters, mapMutations, mapState } from "vuex"

export default {
  name: "JustImageSettings",
  components: {
    Checkbox,
    TwoDirectionPosition,
    SingleButton,
    SettingsDeviceSelector,
    SettingsContentAppearance,
    ContentImages,
    AppearanceImages,
    SettingsElement
  },
  data() {
    return {
      appearanceActiveTab: "image",
      overlayClose: false
    }
  },
  computed: { ...mapGetters("preview", ["getIframeDocuments", "getPageSelector"]), ...mapState("preview", ["appearanceDevice"]) },
  watch: {
    overlayClose(newV) {
      this.getIframeDocuments.forEach((doc) => {
        if (newV) doc.querySelector(`${this.getPageSelector} .overlay`).setAttribute("data-close", "true")
        else doc.querySelector(`${this.getPageSelector} .overlay`).removeAttribute("data-close")
      })
    }
  },
  mounted() {
    if (this.getIframeDocuments[0].querySelector(`${this.getPageSelector} .overlay`).getAttribute("data-close")) {
      this.overlayClose = true
    }
  },
  methods: mapMutations("preview", ["setAppearanceDevice"]),
  created() {
    this.setAppearanceDevice("mobile")
  }
}
</script>

<style scoped></style>
