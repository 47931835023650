<template>
  <h3 class="whitespace-nowrap pt-0 pr-4 pb-0 pl-10 text-xl font-bold border-b border-gray-200 title">
    <slot />
  </h3>
</template>

<script>
export default {
  name: "SettingsTitle"
}
</script>

<style scoped>
.title {
  margin: -1.33rem -1.33rem 1.6rem -1.33rem;
  line-height: 4.5rem;
  height: 4.5rem;
}
</style>
