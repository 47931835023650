<template>
  <SettingsContentAppearance>
    <template slot="content">
      <settings-element>
        <template slot="title">Choose Your Image</template>
        <template slot="body">
          <ContentImages :allow-only-change="true" images-class="images" />
        </template>
      </settings-element>
      <settings-element>
        <template slot="title">Button Actions</template>
        <template slot="body">
          <SingleButton
            :button-area-selector="`${getPageSelector} .images`"
            :inner-editable="false"
            :show-title="false"
            button-class="button-image"
          />
        </template>
      </settings-element>
    </template>
    <template slot="appearance">
      <settings-device-selector />
      <settings-element :key="appearanceDevice">
        <template slot="body">
          <AppearanceImages :show-title="false" images-class="images" />
        </template>
      </settings-element>
    </template>
  </SettingsContentAppearance>
</template>

<script>
import SettingsElement from "@/components/campaign-builder/widget-settings/SettingsElement"
import ContentImages from "@/components/campaign-builder/content-changers/Images"
import AppearanceImages from "@/components/campaign-builder/appearance-changers/Images"
import SettingsContentAppearance from "@/components/campaign-builder/SettingsContentAppearance"
import SettingsDeviceSelector from "@/components/campaign-builder/SettingsDeviceSelector"
import SingleButton from "@/components/campaign-builder/content-changers/SingleButton"
import { mapGetters, mapMutations } from "vuex"

export default {
  name: "ImageButtonSettings",
  components: {
    SingleButton,
    SettingsDeviceSelector,
    SettingsContentAppearance,
    ContentImages,
    AppearanceImages,
    SettingsElement
  },
  computed: mapGetters("preview", ["getPageSelector"]),
  methods: mapMutations("preview", ["setAppearanceDevice"]),
  created() {
    this.setAppearanceDevice("mobile")
  }
}
</script>

<style scoped></style>
