<template>
  <div>
    <input-with-label v-model="name" @input="changeName">Name</input-with-label>
    <CheckboxInput v-model="isRequired" @input="changeIsRequired">Required</CheckboxInput>
  </div>
</template>

<script>
import InputWithLabel from "@/components/common/InputWithLabel"
import CheckboxInput from "@/components/common/Checkbox"

export default {
  name: "Checkbox",
  components: { InputWithLabel, CheckboxInput },
  props: ["elementKey", "eachFormData", "element"],
  data() {
    return {
      isRequired: "",
      name: ""
    }
  },
  methods: {
    changeName() {
      this.eachFormData((f) => {
        f[this.elementKey]["field"].name = this.name
        f[this.elementKey]["field"].parentElement.querySelector("span").innerText = this.name
      })
    },
    changeIsRequired() {
      this.eachFormData((f) => {
        f[this.elementKey]["field"].required = this.isRequired
      })
    }
  },
  mounted() {
    this.isRequired = this.element.required
    this.name = this.element.name
  }
}
</script>
