function parseWidgetHtml(html) {
  const doc = new DOMParser().parseFromString(html, "text/html")

  const styleVariables = {}
  // const styleCore = {}
  const pageNumbers = []
  const totalPage = doc.querySelectorAll(".page").length
  const userStyle = doc.querySelector("style#osm-user")
  // const coreStyle = doc.querySelector("style#osm-core")

  doc.querySelectorAll(".page").forEach((page) => pageNumbers.push(page.dataset["page"]))
  const parentId = doc.querySelector("body div[data-parent]").id

  pageNumbers.forEach((pageNumber) => {
    // style core - old good days...
    // let pageCoreStyle = coreStyle.innerHTML
    // pageNumbers.forEach((pn) => {
    //   if (pn !== pageNumber) {
    //     const styleCoreRegex = new RegExp(`#${parentId} \\.page${pn}[\\s\\S]*?{[\\s\\S]*?}`, "gm")
    //     pageCoreStyle = pageCoreStyle.replace(styleCoreRegex, "")
    //   }
    // })
    // styleCore[pageNumber] = pageCoreStyle

    // style variables
    styleVariables[pageNumber] = {
      selector: `#${parentId} .page${pageNumber}`,
      style: {}
    }

    // style core

    const regexMobile = new RegExp(
      `@media ?\\(max-width:(.?){1,10}\\)( )?{ ?#${parentId} \\.page${pageNumber} ?{([\\s\\S]*?)([\\s\\S]*?)}}`,
      "gm"
    )
    const regexDesktop = new RegExp(
      `@media ?\\(min-width:(.?){1,10}\\)( )?{ ?#${parentId} \\.page${pageNumber} ?{([\\s\\S]*?)([\\s\\S]*?)}}`,
      "gm"
    )
    const regexAll = new RegExp(`#${parentId} \\.page${pageNumber}{([\\s\\S]*?)}`, "gm")

    const matchMobile = regexMobile.exec(userStyle.innerHTML)
    const matchDesktop = regexDesktop.exec(userStyle.innerHTML)
    const matchAll = regexAll.exec(userStyle.innerHTML)

    if (matchMobile !== null && matchDesktop !== null) {
      styleVariables[pageNumber]["style"]["desktop"] = {}
      styleVariables[pageNumber]["style"]["mobile"] = {}

      const desktopCss = matchDesktop[4].split(";").filter((value) => value !== "")
      const mobileCSS = matchMobile[4].split(";").filter((value) => value !== "")
      desktopCss.forEach((cssRow) => {
        styleVariables[pageNumber]["style"]["desktop"][cssRow.split(":")[0]] = cssRow.split(":")[1]
      })
      mobileCSS.forEach((cssRow) => {
        styleVariables[pageNumber]["style"]["mobile"][cssRow.split(":")[0]] = cssRow.split(":")[1]
      })
    } else {
      styleVariables[pageNumber]["style"]["all"] = {}
      const allCss = matchAll[1].split(";").filter((value) => value !== "")
      allCss.forEach((cssRow) => {
        styleVariables[pageNumber]["style"]["all"][cssRow.split(":")[0]] = cssRow.split(":")[1]
      })
    }
  })

  return { styleVariables, parentId, totalPage }
}

export default parseWidgetHtml
