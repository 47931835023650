<template>
  <div class="flex items-center mt-1 mb-2">
    <span class="w-16">Label</span>
    <div class="flex-1">
      <text-input :value="label.innerHTML" padding="small" @input="changeText" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import TextInput from "@/components/common/TextInput"

export default {
  name: "Label",
  components: { TextInput },
  computed: {
    ...mapGetters("preview", ["getPageSelector"])
  },
  props: ["label", "elementKey", "eachFormData", "eachIframeDoc"],
  methods: {
    changeText(text) {
      if (text.length < 1) {
        this.removeLabel()
      } else {
        try {
          this.eachFormData((f) => {
            f[this.elementKey]["label"].innerHTML = text
          })
        } catch {
          this.addLabel()
          this.eachFormData((f) => {
            f[this.elementKey]["label"].innerHTML = text
          })
        }
      }
    },
    addLabel() {
      this.eachIframeDoc((doc) => {
        const fields = doc.querySelector(`${this.getPageSelector} .form`).querySelectorAll(".field")
        const id = fields[this.elementKey].querySelector(".user-input").id
        const label = doc.createElement("label")
        label.setAttribute("for", id)
        fields[this.elementKey].prepend(label)
      })
    },
    removeLabel() {
      this.eachIframeDoc((doc) => {
        const fields = doc.querySelector(`${this.getPageSelector} .form`).querySelectorAll(".field")
        fields[this.elementKey].removeChild(fields[this.elementKey].querySelector("label"))
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.label {
  margin-bottom: 1rem;
  display: flex;
  align-items: stretch;
  justify-content: space-between;

  span {
    display: inline-block;
    margin-right: 0.75rem;
    height: 2rem;
    line-height: 2rem;
  }

  input {
    flex: 1;
    height: 2rem;
  }

  button {
    height: 2rem;
    margin-left: 0.75rem;
    background: var(--primary);
    color: #fff;
    padding: 0 0.75rem;
  }
}
</style>
