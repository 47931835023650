<template>
  <div style="height: 100%">
    <div id="cssEditorDescription" :style="{ height: descriptionHeight }"></div>
    <div id="cssEditor" :style="{ height: `calc(100% - ${descriptionHeight})` }"></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

const description = `/* CSS EDITOR USAGE EXAMPLES */

/* This usage increase letter spacing of page1's title. */
.page1 .title{ letter-spacing: 1px; }

/* This usage affects only desktop devices */
@media screen and (min-width:959px){
  .page1 .title:hover { color: red!important; }
}

/* This usage affects only mobile devices */
@media screen and (max-width:959px){
  .page1 .title:hover { color: red!important; }
}`
export default {
  name: "CssEditor",
  computed: {
    ...mapGetters("preview", ["getIframeDocuments"])
  },
  data() {
    return {
      descriptionHeight: 0
    }
  },
  methods: {},
  mounted() {
    const editor = window.ace.edit("cssEditor")
    editor.session.setMode("ace/mode/css")
    editor.setTheme("ace/theme/textmate")
    editor.setFontSize(14)
    editor.setOptions({
      enableBasicAutocompletion: true,
      autoScrollEditorIntoView: true,
      enableSnippets: false,
      enableLiveAutocompletion: true,
      wrap: true
    })
    if (this.getIframeDocuments[0].querySelector("style#osm-custom").innerHTML.trim().length <= 0) {
      editor.setValue("/* Write your css here */")
    } else {
      editor.setValue(this.getIframeDocuments[0].querySelector("style#osm-custom").innerHTML)
    }

    const descriptionEditor = window.ace.edit("cssEditorDescription")
    descriptionEditor.session.setMode("ace/mode/css")
    descriptionEditor.setTheme("ace/theme/dracula")
    descriptionEditor.setFontSize(14)
    descriptionEditor.setOptions({
      readOnly: true,
      wrap: true,
      useWorker: false
    })
    descriptionEditor.setValue(description)
    descriptionEditor.clearSelection()

    const self = this
    editor.session.on("change", function () {
      self.getIframeDocuments.forEach((doc) => {
        doc.querySelector("style#osm-custom").innerHTML = editor.getValue()
      })
    })
    this.descriptionHeight = descriptionEditor.renderer.lineHeight * 18 + "px"
    let i = 0
    const resizeInterval = setInterval(function () {
      editor.resize()
      descriptionEditor.resize()
      i++
      if (i === 5) clearInterval(resizeInterval)
    }, 500)
  }
}
</script>

<style>
#cssEditor,
#cssEditor *,
#cssEditorDescription,
#cssEditorDescription * {
  font-family: monospace !important;
}
</style>
