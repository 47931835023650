<template>
  <div>
    <settings-title>{{ getTitle }}</settings-title>
    <SettingsDeviceSelector />
    <div :key="appearanceDevice">
      <template v-if="setAreaTopic === 'layout'">
        <SettingsFontFamily variable="--layout-font-family" />
        <SettingsBackgroundColor variable="--layout-background" />
      </template>

      <Images v-if="setAreaTopic === 'image'" images-class="images" />

      <template v-if="setAreaTopic === 'sections'">
        <div v-for="i in sectorElementsCount" :key="i">
          <h3 class="mt-4 text-2xl font-medium border-b border-gray-100 -mb-4">Slice #{{ i }}</h3>
          <SettingsBackgroundColor :variable="`--sector-${i}-fill-color`" />
          <SettingsTextColor :variable="`--label-${i}-fill-color`" class="-mt-4" />
        </div>
      </template>

      <template v-if="setAreaTopic === 'body'">
        <SettingsBackgroundColor variable="--body-background" />
        <SettingsPadding variable="--body-padding" />
      </template>

      <template v-if="setAreaTopic === 'close-button'">
        <SettingsColor title="Foreground Color" variable="--close-button-color" />
        <SettingsBackgroundColor variable="--close-button-background" />
        <SettingsSize :variables="['--close-button-width', '--close-button-height']" title="Icon Size" />
        <SettingsVisibility :can-be-delayed="true" selector=".close-button" variable="--close-button-display" />
        <SettingsPadding variable="--close-button-padding" />
        <SettingsBorderRadius variable="--close-button-border-radius" />
        <SettingsTwoDirectionPosition
          :variable="{
            top: '--close-button-top',
            left: '--close-button-left',
            right: '--close-button-right',
            bottom: '--close-button-bottom'
          }"
        />
      </template>

      <template v-if="setAreaTopic === 'title'">
        <SettingsHidden selector=".title" />
        <SettingsTextColor variable="--title-color" />
        <SettingsBackgroundColor variable="--title-background" />
        <SettingsFontSize variable="--title-font-size" />
        <SettingsMargin variable="--title-margin" />
      </template>

      <template v-if="setAreaTopic === 'subtitle'">
        <SettingsHidden selector=".subtitle" />
        <SettingsTextColor variable="--subtitle-color" />
        <SettingsBackgroundColor variable="--subtitle-background" />
        <SettingsFontSize variable="--subtitle-font-size" />
        <SettingsMargin variable="--subtitle-margin" />
      </template>

      <template v-if="setAreaTopic === 'description'">
        <SettingsHidden selector=".description" />
        <SettingsTextColor variable="--description-color" />
        <SettingsBackgroundColor variable="--description-background" />
        <SettingsFontSize variable="--description-font-size" />
        <SettingsMargin variable="--description-margin" />
      </template>

      <template v-if="setAreaTopic === 'form'">
        <SettingsMargin variable="--form-margin" />
        <SettingsPadding variable="--form-padding" />
      </template>

      <template v-if="setAreaTopic === 'form-fields'">
        <SettingsColor :un-formatted="true" variable="--form-field-color" />
        <SettingsFontSize variable="--form-field-font-size" />
        <SettingsMargin variable="--form-field-margin" />
        <SettingsFormLooking />
      </template>

      <template v-if="setAreaTopic === 'form-labels'">
        <SettingsTextColor variable="--form-label-color" />
        <SettingsFontSize variable="--form-label-font-size" />
        <SettingsMargin variable="--form-label-margin" />
      </template>

      <template v-if="setAreaTopic === 'primary-button'">
        <SettingsTextColor variable="--form-primary-button-color" />
        <SettingsBackgroundColor variable="--form-primary-button-background" />
        <SettingsFontSize variable="--form-primary-button-font-size" />
        <SettingsMargin variable="--form-primary-button-margin" />
        <SettingsPadding variable="--form-primary-button-padding" />
        <SettingsBorderRadius variable="--form-primary-button-border-radius" />
        <SettingsBorder
          color-variable="--form-primary-button-border-color"
          style-variable="--form-primary-button-border-style"
          width-variable="--form-primary-button-border-width"
        />
      </template>

      <template v-if="setAreaTopic === 'secondary-button'">
        <SettingsTextColor variable="--form-secondary-button-color" />
        <SettingsBackgroundColor variable="--form-secondary-button-background" />
        <SettingsFontSize variable="--form-secondary-button-font-size" />
        <SettingsMargin variable="--form-secondary-button-margin" />
        <SettingsPadding variable="--form-secondary-button-padding" />
        <SettingsBorderRadius variable="--form-secondary-button-border-radius" />
        <SettingsBorder
          color-variable="--form-secondary-button-border-color"
          style-variable="--form-secondary-button-border-style"
          width-variable="--form-secondary-button-border-width"
        />
      </template>

      <template v-if="setAreaTopic === 'notes'">
        <SettingsHidden selector=".notes" />
        <SettingsTextColor variable="--notes-color" />
        <SettingsBackgroundColor variable="--notes-background" />
        <SettingsFontSize variable="--notes-font-size" />
        <SettingsMargin variable="--notes-margin" />
      </template>

      <template v-if="setAreaTopic === 'overlay'">
        <SettingsVisibility variable="--overlay-display" />
        <SettingsColor variable="--overlay-color" />
        <SettingsBlur variable="--overlay-backdrop-blur" />
      </template>
    </div>
  </div>
</template>

<script>
import SettingsDeviceSelector from "@/components/campaign-builder/SettingsDeviceSelector"
import Images from "@/components/campaign-builder/appearance-changers/Images"
import appearanceSettingsMixin from "@/mixins/appearance-settings-mixin"
import SettingsTitle from "@/components/campaign-builder/widget-settings/SettingsTitle"
import { mapGetters, mapMutations } from "vuex"

export default {
  name: "WheelOfFortuneAppearance",
  components: {
    SettingsTitle,
    Images,
    SettingsDeviceSelector
  },
  mixins: [appearanceSettingsMixin],
  data() {
    return {
      sectorElementsCount: 0,
      selectorMap: {
        overlay: ".overlay",
        layout: ".inner",
        body: ".body",
        "close-button": ".close-button",
        title: ".title",
        subtitle: ".subtitle",
        description: ".description",
        form: ".form",
        "form-fields": ".form-and-buttons .field",
        "form-labels": ".form-and-buttons .field label:not(.not-parent-label)",
        "primary-button": ".form-and-buttons button.primary",
        "secondary-button": ".form-and-buttons button.secondary",
        notes: ".notes"
      }
    }
  },
  computed: {
    ...mapGetters("preview", ["getIframeDocuments"]),
    getTitle() {
      return {
        overlay: "Overlay Appearance",
        layout: "Layout Appearance",
        image: "Images Appearance",
        sections: "Slices Appearance",
        body: "Body Appearance",
        "close-button": "Close Button Appearance",
        title: "Title Appearance",
        subtitle: "Subtitle Appearance",
        description: "Description Appearance",
        form: "Form Appearance",
        "form-fields": "Form Fields Appearance",
        "form-labels": "Form Labels Appearance",
        "primary-button": "Primary Button Appearance",
        "secondary-button": "Secondary Button Appearance",
        notes: "Notes Appearance"
      }[this.setAreaTopic]
    }
  },
  methods: mapMutations("preview", ["setAppearanceDevice"]),
  created() {
    this.setAppearanceDevice("mobile")
    this.sectorElementsCount = this.getIframeDocuments[0].querySelectorAll(".wheel .sectors path").length
  }
}
</script>
