<template>
  <div>
    <div v-if="imgCount < 1">
      <h2 class="my-4 font-medium text-xl">There is no image.</h2>
    </div>
    <div v-else>
      <h2 v-if="showTitle" class="my-4 font-medium text-xl">Select Image To Change Appearance</h2>
      <button
        v-for="(n, index) in imgCount"
        :key="'btn' + index"
        :class="index === activeImgIndex ? 'active' : ''"
        class="image-select-with-preview"
        @click="activeImgIndex = index"
      >
        <span class="preview">
          <img :src="getImgSrcByIndex(index)" alt="preview-image" />
          <span>Image - {{ index + 1 }}</span>
        </span>
      </button>
      <div v-for="(n, index) in imgCount" :key="'div' + index">
        <div v-if="index === activeImgIndex" :key="n">
          <settings-element v-if="showPosition">
            <template #title>Position</template>
            <template #body>
              <ImagePosition :active-image-index="activeImgIndex" :images-class="imagesClass" />
            </template>
          </settings-element>
          <settings-element-group>
            <settings-element>
              <template #title>Width</template>
              <template #body>
                <ImageWidth :active-image-index="activeImgIndex" :images-class="imagesClass" />
              </template>
            </settings-element>
            <settings-element>
              <template #title>Height</template>
              <template #body>
                <ImageHeight :active-image-index="activeImgIndex" :images-class="imagesClass" />
              </template>
            </settings-element>
          </settings-element-group>
          <settings-element-group>
            <settings-element>
              <template #title>Object Fit</template>
              <template #body>
                <ImageObjectFit :active-image-index="activeImgIndex" :images-class="imagesClass" />
              </template>
            </settings-element>
            <settings-element>
              <template #title>Opacity</template>
              <template #body>
                <ImageOpacity :active-image-index="activeImgIndex" :images-class="imagesClass" />
              </template>
            </settings-element>
          </settings-element-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import ImagePosition from "@/components/campaign-builder/appearance-changers/ImagePosition"
import ImageObjectFit from "@/components/campaign-builder/appearance-changers/ImageObjectFit"
import ImageOpacity from "@/components/campaign-builder/appearance-changers/ImageOpacity"
import ImageWidth from "@/components/campaign-builder/appearance-changers/ImageWidth"
import ImageHeight from "@/components/campaign-builder/appearance-changers/ImageHeight"
import SettingsElement from "@/components/campaign-builder/widget-settings/SettingsElement"

export default {
  name: "Images",
  components: {
    SettingsElement,
    ImageWidth,
    ImageHeight,
    ImageOpacity,
    ImageObjectFit,
    ImagePosition
  },
  props: {
    imagesClass: {
      type: String,
      required: true
    },
    showPosition: {
      type: Boolean,
      default: true
    },
    showTitle: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      imgCount: 0,
      activeImgIndex: null
    }
  },
  computed: {
    ...mapGetters("preview", ["getIframeDocuments", "getPageSelector"])
  },
  mounted() {
    this.setImgCount()
    if (this.imgCount > 0) {
      this.activeImgIndex = 0
    }
  },
  methods: {
    setImgCount() {
      const images = this.getIframeDocuments[0].querySelectorAll(`${this.getPageSelector} .${this.imagesClass} img`)
      this.imgCount = images.length
    },
    getImgSrcByIndex(index) {
      const images = this.getIframeDocuments[0].querySelectorAll(`${this.getPageSelector} .${this.imagesClass} img`)
      return images[index].src
    }
  }
}
</script>
