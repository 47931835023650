<template>
  <div>
    <Checkbox v-model="isRequired" @change="changeIsRequired"> Required</Checkbox>
    <Checkbox v-model="placeholderActive" @change="setPlaceHolder"> Placeholder</Checkbox>
    <text-input
      v-if="placeholderActive"
      v-model="placeholderText"
      class="mb-4"
      padding="small"
      placeholder="Placeholder text"
      type="text"
      @input="setPlaceHolder"
    />
    <div class="select">
      <h3 class="font-medium mb-2">Options</h3>
      <draggable handle=".handle" @end="dragEnd">
        <div
          v-for="(option, key) in element.options"
          v-show="!option.disabled"
          :key="key"
          class="flex relative border border-gray-200 rounded-md mb-2 px-2 pl-6 pr-6"
        >
          <button class="handle absolute -left-2 top-1/2 transform -translate-y-1/2 px-1 py-2 rounded-md bg-blue-600 text-white">
            <icon-bars class="w-5 h-5" />
          </button>
          <button @click="removeOption(key)">
            <icon-x class="w-5 h-5 absolute -top-0 -right-0" />
          </button>
          <div>
            <div>
              <label class="flex items-center my-2">
                <span class="w-16 text-gray-800">Text</span>
                <text-input :value="option.innerHTML" padding="small" @input="(text) => changeOptionText(text, key)" />
              </label>
            </div>
            <div>
              <label class="flex items-center my-2">
                <span class="w-16 text-gray-800">Value</span>
                <text-input :value="option.value" padding="small" @input="(text) => changeOptionValue(text, key)" />
              </label>
            </div>
          </div>
        </div>
      </draggable>
      <button class="mx-auto block" @click="addOption">
        <icon-plus class="w-6 h-6 mx-auto bg-blue-600 text-white rounded-full shadow-md" />
      </button>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable"
import Checkbox from "@/components/common/Checkbox"
import IconX from "@/icons/icon-x.svg"
import IconBars from "@/icons/icon-bars.svg"
import IconPlus from "@/icons/icon-plus.svg"
import TextInput from "@/components/common/TextInput"

export default {
  name: "Select",
  components: {
    TextInput,
    Checkbox,
    draggable,
    IconPlus,
    IconBars,
    IconX
  },
  props: ["element", "eachFormData", "eachIframeDoc", "elementKey", "incKey"],

  data() {
    return {
      isRequired: "",
      placeholderActive: false,
      placeholderText: ""
    }
  },
  methods: {
    addOption() {
      this.eachFormData((fData) => {
        const option = fData[this.elementKey]["field"].ownerDocument.createElement("option")
        option.value = "Value"
        option.text = "Text"
        fData[this.elementKey]["field"].appendChild(option)
      })
      this.incKey()
    },
    removeOption(key) {
      this.eachFormData((f) => {
        const option = f[this.elementKey]["field"].options[key]
        const owner = option.parentElement
        owner.removeChild(option)
      })
      this.incKey()
    },
    changeOptionText(text, key) {
      this.eachFormData((f) => {
        f[this.elementKey]["field"].options[key].innerHTML = text
      })
    },
    changeOptionValue(text, key) {
      this.eachFormData((f) => {
        f[this.elementKey]["field"].options[key].value = text
      })
    },
    dragEnd(event) {
      const { newIndex, oldIndex } = event
      this.eachIframeDoc((doc) => {
        let fields = doc.querySelectorAll(".form .field")[this.elementKey]
        fields = fields.querySelector("select").querySelectorAll("option")
        const newFields = [...fields]

        const tmp = newFields[oldIndex]
        newFields.splice(oldIndex, 1)
        newFields.splice(newIndex, 0, tmp)
        doc.querySelectorAll(".form .field")[this.elementKey].querySelector("select").innerHTML = ""
        newFields.forEach((nf) => {
          let a = doc.querySelectorAll(".form .field")[this.elementKey]
          a.querySelector("select").appendChild(nf)
        })
        doc.querySelectorAll(".form .field")[this.elementKey].querySelector("select").selectedIndex = 0
      })
    },
    setPlaceHolder() {
      if (!this.placeholderActive) {
        this.eachFormData((f) => {
          const opt = f[this.elementKey]["field"].options[0]
          opt.remove()
        })
      } else {
        this.eachFormData((f) => {
          const opt = f[this.elementKey]["field"].options[0]
          if (opt && opt.disabled) {
            opt.innerText = this.placeholderText
          } else {
            f[this.elementKey]["field"].innerHTML =
              `<option selected disabled value="">${this.placeholderText}</option>` + f[this.elementKey]["field"].innerHTML
          }
        })
      }
    },
    changeIsRequired(event) {
      this.eachFormData((f) => {
        f[this.elementKey]["field"].required = event.target.checked
      })
    }
  },
  mounted() {
    this.isRequired = this.element.required
    if (this.element.options[0] && this.element.options[0].disabled) {
      this.placeholderActive = true
      this.placeholderText = this.element.options[0].innerText
    }
  }
}
</script>
<style lang="scss" scoped>
.select {
  .add-new {
    display: block;
    padding: 0.5rem calc(0.5rem + 1px);
    line-height: 1;
    margin: 0 auto;
    background: var(--primary);
    color: #fff;
    border-radius: 100%;
  }
}
</style>
