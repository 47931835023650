<template>
  <div>
    <draggable v-model="formData[0]" handle=".handle" @end="dragEnd">
      <div v-for="(element, key) in formData[0]" :key="key" class="mb-4">
        <div
          :class="key === editElementKey && showEditArea ? 'active bg-blue-600 text-white' : 'bg-gray-200 text-gray-900'"
          class="flex items-center px-4 py-3 justify-between rounded-lg"
        >
          <button class="handle">
            <icon-bars class="w-6 h-6" />
          </button>
          <button class="flex-1" @click="toggleDetails(key)">
            {{ element["name"] }}
          </button>
          <button @click="removeField(key)">
            <icon-x class="w-6 h-6" />
          </button>
        </div>
        <div v-if="showEditArea && key === editElementKey" class="p-4">
          <Label
            v-show="getFormElement['type'] !== 'checkbox'"
            :each-form-data="eachFormData"
            :each-iframe-doc="eachIframeDoc"
            :element-key="editElementKey"
            :label="getFormElement['label']"
          />
          <Name
            v-show="getFormElement['type'] !== 'checkbox' && getFormElement['type'] !== 'rating' && getFormElement['type'] !== 'radios'"
            :each-form-data="eachFormData"
            :element="getFormElement['field']"
            :element-key="editElementKey"
          />
          <Component
            :is="getFormElement.editComponent"
            :key="sKey"
            :each-form-data="eachFormData"
            :each-iframe-doc="eachIframeDoc"
            :element="getFormElement['field']"
            :element-key="editElementKey"
            :incKey="incSKey"
          />
        </div>
      </div>
    </draggable>
    <add-new-field @added="mountFormFields" />
  </div>
</template>

<script>
import Label from "@/components/campaign-builder/content-changers/general-form-fields/form-fields/Label"
import InputText from "@/components/campaign-builder/content-changers/general-form-fields/form-fields/InputText"
import Select from "@/components/campaign-builder/content-changers/general-form-fields/form-fields/Select"
import Name from "@/components/campaign-builder/content-changers/general-form-fields/form-fields/Name"
import Email from "@/components/campaign-builder/content-changers/general-form-fields/form-fields/Email"
import Checkbox from "@/components/campaign-builder/content-changers/general-form-fields/form-fields/Checkbox"
import Rating from "@/components/campaign-builder/content-changers/general-form-fields/form-fields/Rating"
import Radio from "@/components/campaign-builder/content-changers/general-form-fields/form-fields/Radio"
import InputTel from "@/components/campaign-builder/content-changers/general-form-fields/form-fields/InputTel"
import draggable from "vuedraggable"
import { mapGetters } from "vuex"
import formFields from "@/components/campaign-builder/content-changers/general-form-fields/form-fields/FormFields"
import Textarea from "@/components/campaign-builder/content-changers/general-form-fields/form-fields/Textarea"
import AddNewField from "@/components/campaign-builder/content-changers/general-form-fields/AddNewField"
import IconBars from "@/icons/icon-bars.svg"
import IconX from "@/icons/icon-x.svg"

export default {
  name: "ContentFormFields",
  components: {
    AddNewField,
    Textarea,
    InputTel,
    Radio,
    Rating,
    Checkbox,
    Email,
    Name,
    Select,
    InputText,
    Label,
    draggable,
    IconBars,
    IconX
  },
  computed: {
    ...mapGetters("preview", ["getIframeDocuments", "getPageSelector"]),
    getFormElement() {
      return this.formData[0][this.editElementKey]
    }
  },
  data() {
    return {
      formData: [],
      showEditArea: false,
      editElementKey: 0,
      sKey: 0,
      showAddNewArea: false
    }
  },
  methods: {
    incSKey() {
      this.sKey += 1
    },
    toggleDetails(key) {
      this.showEditArea = this.editElementKey !== key || this.showEditArea === false
      this.editElementKey = key
    },
    analyzeFormFields(fields) {
      const data = []
      fields.forEach((field) => {
        const obj = {}
        const label = field.querySelector("label:not(.not-parent-label)")

        if (label) obj["label"] = label
        else obj["label"] = false

        formFields.forEach((formField) => {
          if (field.querySelector(formField.selector)) {
            obj["field"] = field.querySelector(formField.selector)
            obj["type"] = formField.type
            obj["field_name"] = field.querySelector(formField.nameSelector).name
            obj["name"] = formField.name
            obj["editComponent"] = formField.component
          }
        })
        data.push(obj)
      })
      return data
    },
    mountFormFields() {
      const data = []
      this.getIframeDocuments.forEach((doc) => {
        const formFields = doc.querySelector(`${this.getPageSelector} form`).querySelectorAll(".field")
        data.push(this.analyzeFormFields(formFields))
      })
      this.formData = data
    },
    eachFormData(callback) {
      Object.values(this.formData).forEach(callback)
      this.mountFormFields()
    },
    eachIframeDoc(callback) {
      this.getIframeDocuments.forEach(callback)
      this.mountFormFields()
    },
    removeField(editElementKey) {
      this.eachFormData((f) => {
        let element = f[editElementKey]["field"]
        element._flatpickr && element._flatpickr.destroy()
        const w = element.ownerDocument.defaultView
        w.intlTelInputGlobals && w.intlTelInputGlobals.getInstance(element) && w.intlTelInputGlobals.getInstance(element).destroy()

        while (!element.classList.contains("field")) {
          element = element.parentElement
        }
        element.parentElement.removeChild(element)
      })
    },
    dragEnd(event) {
      const { newIndex, oldIndex } = event
      this.editElementKey = null
      this.showEditArea = false
      this.eachIframeDoc((doc) => {
        const fields = doc.querySelectorAll(`${this.getPageSelector} form .field`)
        const newFields = [...fields]
        const tmp = newFields[oldIndex]
        newFields.splice(oldIndex, 1)
        newFields.splice(newIndex, 0, tmp)
        doc.querySelectorAll(`${this.getPageSelector} form`).innerHTML = ""
        newFields.forEach((nf) => {
          doc.querySelector(`${this.getPageSelector} form`).appendChild(nf)
        })
      })
    }
  },
  created() {
    this.mountFormFields()
  }
}
</script>
