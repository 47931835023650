<template>
  <header class="header flex justify-between items-center text-white bg-gray-900 px-8">
    <h1 class="text-2xl font-bold tracking-wider">Campaign Builder</h1>
    <div class="flex items-center">
      <button class="font-medium mr-4 px-2 cursor-pointer" @click="generateHtml">Download As HTML</button>
      <button class="more-realistic-preview font-medium block px-2 relative z-40">
        <span>More Realistic Preview</span>
        <div class="submenu absolute w-full top-full pt-2">
          <div class="flex px-2 bg-white flex-col py-2 rounded-md shadow-md">
            <button class="sub-element relative flex-1 my-1 py-2 rounded-md px-4 bg-blue-600 hover:bg-blue-700 transition-all text-white">
              <span>Mobile</span>
              <div class="sub-submenu absolute z-50 w-44 px-4 py-2 top-0 flex flex-col right-full rounded-md shadow-lg bg-white">
                <button
                  v-for="(deviceName, resolution) in devices.mobile"
                  :key="resolution"
                  class="block w-full my-1 py-2 bg-indigo-600 hover:bg-indigo-700 transition-all text-white rounded-md"
                  @click="moreRealisticPreview(resolution, 'mobile')"
                >
                  {{ deviceName }}
                </button>
              </div>
            </button>
            <button class="sub-element relative flex-1 my-1 py-2 rounded-md px-4 bg-blue-600 hover:bg-blue-700 transition-all text-white">
              <span>Desktop</span>
              <div class="sub-submenu absolute z-50 w-44 px-4 py-2 top-0 flex flex-col right-full rounded-md shadow-lg bg-white">
                <button
                  v-for="(deviceName, resolution) in devices.desktop"
                  :key="resolution"
                  class="block w-full my-1 py-2 bg-indigo-600 hover:bg-indigo-700 transition-all text-white rounded-md"
                  @click="moreRealisticPreview(resolution, 'desktop')"
                >
                  {{ deviceName }}
                </button>
              </div>
            </button>
          </div>
        </div>
      </button>
    </div>
  </header>
</template>
<script>
import { mapGetters } from "vuex"
import getScreenshot from "@/api/get-screenshot"

export default {
  name: "CampaignBuilderHeader",
  props: {
    generateHtml: {}
  },
  data() {
    return {
      devices: {
        mobile: {
          "414-736": "Iphone 6/7/8 Plus",
          "320-568": "Iphone 5/S",
          "375-812": "Iphone X",
          "360-740": "Samsung S9"
        },
        desktop: {
          "1024-768": "Mini Desktop",
          "1366-768": "Middle Desktop",
          "1920-1080": "Big Desktop"
        }
      }
    }
  },
  computed: mapGetters("preview", ["getIframes"]),
  methods: {
    moreRealisticPreview(resolution, iframeDeviceKey) {
      const splitResolution = resolution.split("-")
      // console.log(splitResolution)
      const width = splitResolution[0]
      const height = splitResolution[1]
      const size = `width=${width},height=${height},resizable=0`
      const preview = window.open("", "MsgWindow" + resolution, size)
      const iframe = this.getIframes[iframeDeviceKey]
      preview.document.write(iframe.contentWindow.document.documentElement.innerHTML)
      getScreenshot({ url: "https://wisepops.com", width, height }).then((screenshot) => {
        preview.document.querySelector("body").style.backgroundImage = `url("${screenshot}")`
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.header {
  height: var(--campaign-builder-header-height);

  .submenu {
    @apply hidden;
  }

  .more-realistic-preview:hover .submenu {
    @apply block;
  }

  .sub-element .sub-submenu {
    @apply hidden;
  }

  .sub-element:hover .sub-submenu {
    @apply block;
  }
}
</style>
